// MenuCell.jsx
import React from "react";
import "./SelectedRightClickMenu.css";

const SelectedMenuCell = ({
  text,
  keyboard_cmd,
  hasDivider = false,
  onClick,
}) => {
  const handleClick = () => {
    onClick(text); // Pass the text to the click event handler
  };

  return (
    <div
      className={`rightclick-menu-cell ${hasDivider ? "with-divider" : ""}`}
      onClick={handleClick} // Attach click event handler
    >
      <span className="menu-left-text">{text}</span>
      <span className="menu-right-text">{keyboard_cmd}</span>
    </div>
  );
};

export default SelectedMenuCell;
