import React, { useState, useEffect } from "react";
import MarketSideBar from "../../components/Stickers/MarketSideBar/MarketSideBar";
import StickerSearch from "../../components/Stickers/StickerSearch/StickerSearch";
import StickerSearchResults from "../../components/Stickers/StickerSearch/StickerSearchResults";
import ThisCraftBar from "../../components/Stickers/ThisCraftBar/ThisCraftBar";
import TabSystemContainer from "../../components/Stickers/TabSystem/TabSystem";
import "./StickersPage.css";
import ChooseStickerPopupModal from "../../components/PopupModals/ChooseStickerPopupModal/ChooseStickerPopupModal";
import GraphicsScene from "../../components/Stickers/Canvas/GraphicsScene";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  makeStockPopupVisible,
  makeStockPopupHidden,
} from "../../redux/slices/cartSessionSlice";

const StickersPage = () => {
  const [stickerSizeWeaponModel, setStickerSizeWeaponModel] = useState(200);

  // market bar (left side).
  const [marketFilterBarVisible, setMarketFilterBarVisible] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  // Filter for Price Type Color Stock
  const [collection, setCollection] = useState([null, null, null, null]);

  // this craft bar.
  const [thisCraftBarVisible, setthisCraftBarVisible] = useState(true);
  const [selectedWeapon, setSelectedWeapon] = useState(null);
  const [displayWeapon, setDisplayWeapon] = useState(null);

  // modal visibility
  const [isModalVisible, setIsModalVisible] = useState(true);

  // toggle market filter bar (left side).
  const toggleMarketFilterBar = () => {
    setMarketFilterBarVisible(!marketFilterBarVisible);
  };

  // changes search query in market bar.
  const handleSearchQueryChange = (query) => {
    setSearchQuery(query);
  };

  // changes collection in market bar.
  const handleCollectionChange = (index, item) => {
    setCollection((prevCollection) => {
      const updatedCollection = [...prevCollection];
      updatedCollection[index] = item;
      return updatedCollection;
    });
  };

  // toggle this craft bar (right side).
  const toggleThisCraftBar = () => {
    setthisCraftBarVisible(!thisCraftBarVisible);
  };

  // set sticker size depending on display weapon.
  useEffect(() => {
    if (displayWeapon) {
      setStickerSizeWeaponModel(displayWeapon.sticker_size);
    }
  }, [displayWeapon]);

  const dispatch = useDispatch();

  // close modal
  const handleCloseModal = () => {
    dispatch(makeStockPopupHidden());
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100vh",
      }}
    >
      <ChooseStickerPopupModal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
      >
        <p>Choose your sticker here!</p>
      </ChooseStickerPopupModal>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            height: "50px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <div
              style={{
                height: "50px",
                display: "flex",
                flexDirection: "row",
                marginTop: "3px",
              }}
            >
              <div style={{ width: "220px", backgroundColor: "yellow" }}>
                <button
                  className="sidebar-toggle-button"
                  // onClick={toggleMarketFilterBar}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "220px",
                    height: "50px",
                    backgroundColor: "#171723",
                    color: "#fff",
                    fontWeight: "800",
                    fontSize: "14px",
                    border: "none",
                    borderRadius: "0px",
                    padding: "0 10px",
                    cursor: "default"

                  }}
                >
                  <img
                    src={"/images/filter.png"}
                    alt="Button Icon"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  Filters
                </button>
              </div>
              <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                <div
                  style={{
                    marginLeft: "3px",
                    marginRight: "3px",
                    width: "240px",
                  }}
                >
                  <StickerSearch setSearchQuery={handleSearchQueryChange} />
                </div>
                <div
                  className="right-side-topbar"
                  style={{
                    width: "100%", // Change width to 100%
                    flex: 1,
                    backgroundColor: "#171723",
                    display: "flex",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ backgroundColor: "red", width: "50%" }}></div>
                    <div
                      style={{ backgroundColor: "blue", width: "50%" }}
                    ></div>
                  </div>
                  <TabSystemContainer
                    onSelectWeapon={selectedWeapon}
                    setDisplayWeapon={setDisplayWeapon}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "240px",
                  backgroundColor: "yellow",
                  marginLeft: "3px",
                }}
              >
                <button
                  className="sidebar-toggle-button"
                  // onClick={toggleThisCraftBar}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "240px",
                    height: "50px",
                    backgroundColor: "#171723",
                    color: "#fff",
                    fontWeight: "800",
                    fontSize: "14px",
                    border: "none",
                    borderRadius: "0px",
                    padding: "0 10px",
                    cursor: "default"
                  }}
                >
                  <img
                    src={"/images/tools.png"}
                    alt="Button Icon"
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  This Craft
                </button>
              </div>
            </div>
            <div
              style={{
                flex: 1,
                position: "relative",
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              {marketFilterBarVisible && (
                <div style={{ width: "220px", marginRight: "3px" }}>
                  <MarketSideBar setCollection={handleCollectionChange} />
                </div>
              )}

              <div style={{ width: "243px", backgroundColor: "#111117" }}>
                <StickerSearchResults
                  // handleStickerClick={addStickerToCanvas}
                  searchQuery={searchQuery} // Pass searchQuery to StickerSearchResults
                  collection={collection} // Pass searchQuery to StickerSearchResults
                />
              </div>

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 0,
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      pointerEvents: "none",
                    }}
                  >
                    <GraphicsScene onSelectWeapon={displayWeapon} />
                  </div>
                </div>
              </div>
              {/* Right section for "this craft" */}
              {thisCraftBarVisible && (
                <div
                  style={{
                    width: "240px",
                    backgroundColor: "#171723",
                    marginTop: "3px",
                  }}
                >
                  <ThisCraftBar
                    onSelectWeapon={setSelectedWeapon}
                    appliedStickers={[]}
                    selected_sticker={{}}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickersPage;
