import React, { useState,useEffect } from "react";
import "./CartSteamTrade.css"; // Import CSS file for styling
import TradingBotDisplay from './TradingBotDisplay'
import { useSelector } from "react-redux";


const CartSteamTrade = () => {
  const [receivedItems,setReceivedItems] = useState([]); // Example receive items
  const orderID = useSelector((state) => state.cart_session.current_order_id);

  const getTrades = () => {
    // Create PaymentIntent as soon as the page loads
    fetch(process.env.REACT_APP_API_URL + "auth/fetchLastOrderTrades", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },      
      body: JSON.stringify({
        orderID: orderID})
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 500)
          return console.log("Error trying to create mock payment");
        console.log(response);
      })
      .then((resObject) => {
        console.log(resObject.trades); // This should log the order ID
        if(resObject.success){
          setReceivedItems(resObject.trades)
        }
      });
  };
  
  useEffect(() => {
    getTrades();
  }, []);

  return (
    <div className="steam-trade-page-wrapper">
      <div className="steam-trade-page-title-wrapper">
        <p>STEAM ALL ACTIVE TRADES </p>
      </div>

      <div className="steam-trade-page-bots-wrapper">
        {receivedItems.map((trade, index) => (
          <>
            <TradingBotDisplay
            key={index}
          bot_name={trade.steam_name}
          bot_created_date={trade.trade_date}
          send_items={[{
            name: trade.steam_name,
            item_name: trade.name,
          }]}
          sender_avatar={trade.avatar}
          receive_items={[]}
          trade_status={trade.status}
        />
          </>
        ))}
      </div>
    </div>
  );
};

export default CartSteamTrade;
