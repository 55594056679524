// EmptyCartCell.js

import React from "react";
import "./Cart.css";

const EmptyCartCell = () => {
  return (
    <div className="empty-cart-cell">
      <div style={{ textAlign: "center" }}>
        <img
          src="images/illustrations/empty-cart-illustration.png"
          alt="Empty Cart Image"
          className="sticker-image-empty-cart"
          style={{ width: "60%", height: "auto", margin: "0 auto" }} // Set width to 60%, height auto, and center horizontally
        />
        <p style={{ color: "#fff", fontWeight: "bold", fontSize: "14px", marginTop: "10px" }}>
          THIS CART IS EMPTY
        </p>
      </div>
    </div>
  );
};

export default EmptyCartCell;
