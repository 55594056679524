// slices/DetailUserSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const popupDateSlice = createSlice({
  name: 'popup_data',
  initialState: {
    popup_data: {},
  },
  reducers: {
    setPopupData: (state, action) => {
      state.popup_data = action.payload;
    },
  },
});

export const { setPopupData } = popupDateSlice.actions;

export default popupDateSlice.reducer;
