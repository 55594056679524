// MenuCell.jsx
import React from "react";
import "./UnSelectedRightClickMenu.css";

const UnSelectedMenuCell = ({ text, keyboard_cmd, hasDivider = false, onClick }) => {
  const handleClick = () => {
    onClick(text); // Pass the text to the click event handler
  };

  return (
    <div
      className={`unselected-rightclick-menu-cell ${hasDivider ? "with-divider" : ""}`}
      onClick={handleClick} // Attach click event handler
    >
      <span className="unselected-menu-left-text">{text}</span>
      <span className="unselected-menu-right-text">{keyboard_cmd}</span>
    </div>
  );
};

export default UnSelectedMenuCell;
