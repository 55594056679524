import React, { useRef, useEffect } from "react";
import { NavLink } from "react-router-dom"; // Import NavLink for navigation
import CartPopup from "./CartPopup"; // Make sure the path to CartPopup is correct

import { useDispatch, useSelector } from "react-redux";
import {
  showCartDropDown,
  hideCartDropDown,
} from "../../../redux/slices/cartSessionSlice";

const CartSection = ({ user, openCartPopup }) => {
  const cartDetailsRef = useRef(null);
  const redux_cart = useSelector((state) => state.cart_session);
  const dispatch = useDispatch();

  const toggleCartDetails = () => {
    if (redux_cart.cart_dropdown_visible) {
      dispatch(hideCartDropDown());
    } else {
      dispatch(showCartDropDown());
    }
  };

  const item_count = redux_cart.cart_items.length;
  const checkout_stage = redux_cart.checkout_stage;

  const CartIcon = (
    <div
      className="top-bar-cart"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        paddingLeft: "12px",
        height: "36px",
      }}
      onClick={toggleCartDetails}
    >
      <div style={{ position: "relative", height: "30px", width: "37px" }}>
        <img
          className="cart-image"
          src="/images/cart.png"
          alt="Cart_Icon"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: "0",
            right: "0",
            width: "20px",
            height: "20px",
            backgroundColor: "#2E8FFF",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "2",
            userSelect: "none",
          }}
        >
          <span style={{ fontSize: "10px", fontFamily: "'Kanit'" }}>
            {item_count}
          </span>
        </div>
      </div>
      {redux_cart.cart_dropdown_visible && (
        <CartPopup cartDetailsRef={cartDetailsRef} user={user} />
      )}

    </div>
  );

  return checkout_stage > 1 ? (
    <NavLink to="/checkout">{CartIcon}</NavLink>
  ) : (
    CartIcon
  );
};

export default CartSection;
