// CoordinateSystemUtils.jsx

import * as THREE from 'three';

export const loadCoordinateSystem = (scene) => {
  // Remove existing axes if they exist
  const existingXAxis = scene.getObjectByName('xAxis');
  const existingYAxis = scene.getObjectByName('yAxis');
  const existingZAxis = scene.getObjectByName('zAxis');

  if (existingXAxis) {
    scene.remove(existingXAxis);
    existingXAxis.geometry.dispose();
    existingXAxis.material.dispose();
  }
  if (existingYAxis) {
    scene.remove(existingYAxis);
    existingYAxis.geometry.dispose();
    existingYAxis.material.dispose();
  }
  if (existingZAxis) {
    scene.remove(existingZAxis);
    existingZAxis.geometry.dispose();
    existingZAxis.material.dispose();
  }

  // Helper function to create an axis
  const createAxis = (points, color, name) => {
    const material = new THREE.LineBasicMaterial({
      color: color,
    }); // Set line width
    const geometry = new THREE.BufferGeometry().setFromPoints(points);
    const axis = new THREE.Line(geometry, material);
    axis.name = name;
    return axis;
  };

  // Create the x-axis (Red)
  const xAxis = createAxis(
    [new THREE.Vector3(-300, 0, -25), new THREE.Vector3(300, 0, -25)],
    0xff0000,
    'xAxis'
  );

  // Create the y-axis (Green)
  const yAxis = createAxis(
    [new THREE.Vector3(0, -300, -25), new THREE.Vector3(0, 300, -25)],
    0x00ff00,
    'yAxis'
  );

  // Create the z-axis (Blue)
  const zAxis = createAxis(
    [new THREE.Vector3(0, 0, -25), new THREE.Vector3(0, 0, 300)],
    0x0000ff,
    'zAxis'
  );

  // Add the axes to the scene
  scene.add(xAxis);
  scene.add(yAxis);
  scene.add(zAxis);
};
