import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import {
  createScopeMaterial,
  createStickerMaterial,
  createWeaponMaterial,
} from "./ThreeMaterials"; // Import shader material functions
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader.js';

const textureLoader = new THREE.TextureLoader();
const objLoader = new OBJLoader();
const mtlLoader = new MTLLoader();


const LoadWeaponModel = (weaponModel, sceneRef) => {
  const scene = sceneRef.current;
  const existingModel = scene.getObjectByName("weaponModel");
  const existingLogoPlane = scene.getObjectByName("logoPlane");
  const existingStickerPlate = scene.getObjectByName("stickerPlate");
  const existingFrontScope = scene.getObjectByName("frontScope");
  const existingBackScope = scene.getObjectByName("backScope");
  const existingScopeModel = scene.getObjectByName("scopeModel");

  // cleanup
  if (
    existingModel ||
    existingLogoPlane ||
    existingStickerPlate ||
    existingBackScope ||
    existingFrontScope ||
    existingScopeModel
  ) {
    scene.remove(existingModel);
    scene.remove(existingLogoPlane);
    scene.remove(existingStickerPlate);
    scene.remove(existingFrontScope);
    scene.remove(existingBackScope);
    scene.remove(existingScopeModel);
  }

  // Load the weapon model
  objLoader.load(
    weaponModel.obj_path,
    (object) => {
      const modelScale = weaponModel.model_scale;

      object.rotation.x = -Math.PI / 2;
      // object.rotation.x = -Math.PI / 4;

      object.rotation.z = Math.PI;

      object.scale.set(1 * modelScale, 1 * modelScale, 1 * modelScale);

      const boundingBox = new THREE.Box3().setFromObject(object);
      const center = boundingBox.getCenter(new THREE.Vector3());
      object.position.sub(center);

      object.position.x += weaponModel.x_offset;
      object.position.y += weaponModel.y_offset;
      object.position.z = -25;

      const skinTexture = textureLoader.load(weaponModel.skin_texture_path);

      object.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.material = createWeaponMaterial({
            skinTexture,
          });
        }
      });

      object.name = "weaponModel";
      scene.add(object);
    },
    (xhr) => {},
    (error) => {
      console.error("Error loading OBJ file:", error);
    }
  );

  /**
   * Load sticker plate
   */
  objLoader.load(
    weaponModel.sticker_plate_path,
    (object) => {
      object.rotation.x = -Math.PI / 2;
      object.rotation.z = Math.PI;

      object.scale.set(
        weaponModel.model_scale,
        weaponModel.model_scale,
        weaponModel.model_scale
      );

      const boundingBox = new THREE.Box3().setFromObject(object);
      const center = boundingBox.getCenter(new THREE.Vector3());
      object.position.sub(center);

      object.position.x += weaponModel.sticker_plate_offset_x;
      object.position.y += weaponModel.sticker_plate_offset_y;
      object.position.z = -24.4;

      object.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child.geometry.computeVertexNormals();
          child.material.side = THREE.DoubleSide;
          child.material.needsUpdate = true;
          child.material.wireframe = false;

          child.material.polygonOffset = true;
          child.material.polygonOffsetFactor = -1; // Adjust these values as needed
          child.material.polygonOffsetUnits = -1; // Adjust these values as needed

          child.material.transparent = true;
          child.material.opacity = 0.01; // Almost transparent, but not fully 0.01
          child.material.depthWrite = false; // Prevents writing to the depth buffer
          child.material.depthTest = true; // Tests against the depth buffer
        }
      });

      object.name = "stickerPlate";
      scene.add(object);
    },
    (xhr) => {
      
    },
    (error) => {
      console.error("Error loading OBJ file:", error);
    }
  );

  /**
   * Load shared scope in special cases
   */

  if (weaponModel.front_scope_scale) {
    objLoader.load(
      "images/objects/shared_scope/shared_scope.obj",
      (object) => {
        object.rotation.x = -Math.PI / 2;
        // object.rotation.x = -Math.PI / 4;

        object.rotation.z = Math.PI;

        object.scale.set(
          weaponModel.front_scope_scale,
          weaponModel.front_scope_scale,
          weaponModel.front_scope_scale
        );

        const boundingBox = new THREE.Box3().setFromObject(object);
        const center = boundingBox.getCenter(new THREE.Vector3());
        object.position.sub(center);

        object.position.x += weaponModel.front_scope_x_offset;
        object.position.y += weaponModel.front_scope_y_offset;
        object.position.z = -25 + weaponModel.front_scope_z_offset;

        const scopetexture = textureLoader.load(
          "images/objects/shared_scope/shared_scope_vmat_g_tcolor_b1605615.png"
        );

        object.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material = createScopeMaterial({
              scopetexture,
            });
          }
        });

        object.name = "frontScope";
        scene.add(object);
      },
      (xhr) => {},
      (error) => {
        console.error("Error loading OBJ file:", error);
      }
    );
  }

  if (weaponModel.back_scope_scale) {
    objLoader.load(
      "images/objects/shared_scope/shared_scope.obj",
      (object) => {
        object.rotation.x = -Math.PI / 2;
        // object.rotation.x = -Math.PI / 4;

        object.rotation.z = Math.PI;

        object.scale.set(
          weaponModel.back_scope_scale,
          weaponModel.back_scope_scale,
          weaponModel.back_scope_scale
        );

        const boundingBox = new THREE.Box3().setFromObject(object);
        const center = boundingBox.getCenter(new THREE.Vector3());
        object.position.sub(center);

        object.position.x += weaponModel.back_scope_x_offset;
        object.position.y += weaponModel.back_scope_y_offset;
        object.position.z = -25 + weaponModel.back_scope_z_offset;

        const scopetexture = textureLoader.load(
          "images/objects/shared_scope/shared_scope_vmat_g_tcolor_b1605615.png"
        );

        object.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material = createScopeMaterial({
              scopetexture,
            });
          }
        });

        object.name = "backScope";
        scene.add(object);
      },
      (xhr) => {},
      (error) => {
        console.error("Error loading OBJ file:", error);
      }
    );
  }

  if (weaponModel.scope_model_path) {
    objLoader.load(
      weaponModel.scope_model_path,

      (object) => {
        // object.rotation.x = -Math.PI / 2;
        // object.rotation.y = Math.PI / 2;
        object.rotation.x = -Math.PI;
        // object.rotation.x = -Math.PI / 4;

        object.rotation.z = Math.PI;

        object.scale.set(
          weaponModel.scope_model_scale,
          weaponModel.scope_model_scale,
          weaponModel.scope_model_scale
        );

        const boundingBox = new THREE.Box3().setFromObject(object);
        const center = boundingBox.getCenter(new THREE.Vector3());
        object.position.sub(center);

        object.position.x += weaponModel.scope_model_x_offset;
        object.position.y += weaponModel.scope_model_y_offset;
        object.position.z = -25 + weaponModel.scope_model_z_offset;

        const skinTexture = textureLoader.load(weaponModel.scope_model_texture);

        object.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material = createWeaponMaterial({
              skinTexture,
            });
          }
        });

        object.name = "scopeModel";
        scene.add(object);
      },
      (xhr) => {},
      (error) => {
        console.error("Error loading OBJ file:", error);
      }
    );
  }

  // Create and add the plane with the texture
  const scalar = weaponModel.logo_plane_scalar / 40;
  const planeGeometry = new THREE.PlaneGeometry(180, 108); // Adjust size as needed
  planeGeometry.scale(scalar, scalar, 1);
  const planeMaterial = createStickerMaterial(
    "images/craft_n_buy_stickers_banner.png"
  );
  const plane = new THREE.Mesh(planeGeometry, planeMaterial);

  /**
   * 
   * "logo_plane_x": 20,
    "logo_plane_y": -25,
   */

  plane.position.set(weaponModel.logo_plane_x, weaponModel.logo_plane_y, -60); // Position the plane at (0, 0, -21)
  plane.name = "logoPlane";

  scene.add(plane);
};

export default LoadWeaponModel;
