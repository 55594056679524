import React from "react";
const AdBanner = () => {
    return (
        <div className="top-ad-banner" style={{width:"100%"}}>
        <img
          src="/images/top_horizontal_banner/beta_release_banner.png"
          alt="Left Image"
          style={{ marginRight: "10px"}}
        />
        </div>
    )
}
export default AdBanner

