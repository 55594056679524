import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./BalanceAndTradeStyle.css"; // Ensure this path is correct based on your project structure
import { useDispatch } from "react-redux";

const BalanceAndTransactionsTable = ({ headers, data, title, detailPage }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const rowsPerPage = 12;
  const navigate = useNavigate();

  // Handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  // Trim search query and check if it is empty
  const trimmedSearchQuery = searchQuery.trim().toLowerCase();
  const filteredData = data.filter((row) => {
    return headers.some((header) => {
      const cellValue = row[header.key]?.toString().toLowerCase() || "";
      return cellValue.includes(trimmedSearchQuery);
    });
  });

  // Calculate pagination values
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentData = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  // Change page
  const paginate = (pageNumber) => {
    if (pageNumber < 1) {
      setCurrentPage(1);
    } else if (pageNumber > totalPages) {
      setCurrentPage(totalPages);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  // Generate pagination links
  const paginationLinks = [];
  if (totalPages > 1) {
    // First page button
    paginationLinks.push(
      <button
        key="first"
        onClick={() => paginate(1)}
        disabled={currentPage === 1}
      >
        {"<<"}
      </button>
    );

    // Previous page button
    paginationLinks.push(
      <button
        key="prev"
        onClick={() => paginate(currentPage - 1)}
        disabled={currentPage === 1}
      >
        {"<"}
      </button>
    );

    // Current page button
    paginationLinks.push(
      <button
        key={currentPage}
        onClick={() => paginate(currentPage)}
        className="current"
      >
        {currentPage}
      </button>
    );

    // Next page button
    paginationLinks.push(
      <button
        key="next"
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        {">"}
      </button>
    );

    // Last page button
    paginationLinks.push(
      <button
        key="last"
        onClick={() => paginate(totalPages)}
        disabled={currentPage === totalPages}
      >
        {">>"}
      </button>
    );
  }

  // Navigate to detail page if required
  const navigateToDetailPage = (row) => {
    if (detailPage === "users") {
      navigate(`${row.steam64id}`);
    }
    if (detailPage === "orders") {
    }
  };

  return (
    <>
      {currentData.length > 0 ? (
        <div className="data-table-outer-wrapper">
          <div className="data-table-header">
            <p className="data-table-title">{title}</p>
          </div>
          <div className="data-table-wrapper">
            {/* Search bar */}
            <input
              type="text"
              className="search-bar-transactions"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <table className="data-table">
              <thead>
                <tr>
                  {headers.map((header) => (
                    <th key={header.key}>{header.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentData.map((row, rowIndex) => (
                  <tr key={rowIndex} onClick={() => navigateToDetailPage(row)}>
                    {headers.map((header) => (
                      <td key={header.key}>{row[header.key]}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination controls */}
            <div className="pagination">{paginationLinks}</div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default BalanceAndTransactionsTable;
