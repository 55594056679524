import React, { Component } from "react";

const GridCell = () => {
  const cellStyle = {
    width: "500px",
    height: "500px",
    position: "relative", // To position the image within the cell
  };

  const imageStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // Center the image
    opacity: 0.4, // Set opacity to 50%
  };

  return (
    <div style={cellStyle}>
      <img
        src="images/watermarks/watermark.png"
        alt="Watermark"
        style={imageStyle}
      />
    </div>
  );
};

class WatermarkGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numCells: this.calculateNumCells(),
    };
    this.updateNumCells = this.updateNumCells.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateNumCells);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateNumCells);
  }

  calculateNumCells() {
    const viewportWidth = window.innerWidth;
    const cellWidth = 400;
    return Math.ceil(viewportWidth / cellWidth) * 3; // Adjust the multiplication factor as needed
  }

  updateNumCells() {
    this.setState({ numCells: this.calculateNumCells() });
  }

  render() {
    const gridContainerStyle = {
      width: "100%",
      height: "100%",
      display: "grid",
      gridTemplateColumns: `repeat(${Math.ceil(
        this.state.numCells / 3
      )}, 500px)`, // Adjust the multiplication factor as needed
      gap: "0px", // Gap between cells
      padding: "0px", // Padding for the entire grid
    };

    const gridCells = [];
    for (let i = 0; i < this.state.numCells; i++) {
      gridCells.push(<GridCell key={i} />);
    }

    return <div style={gridContainerStyle}>{gridCells}</div>;
  }
}

export default WatermarkGrid;
