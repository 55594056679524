import React from "react";
import "../CheckoutPage.css"; // Import CSS file for styling
import CartItemBox from "../../../components/Checkout/CartItemBox/CartItemBox";
import OrderPaymentBox from "../../../components/Checkout/OrderPaymentBox/OrderPaymentBox";

const CartPayment = ({ onProceedToSteamTrade,user }) => {
  return (
    <div className="cart-container-P">
      <div className="cart-items-container-P">
        <div className="cart-item-box-P">
          <CartItemBox user = {user} />
        </div>
      </div>
      <div className="order-summary-container-P">
        {/* Content for right box */}
        <div className="order-confirm-box-P">
          <OrderPaymentBox onProceedToSteamTrade={onProceedToSteamTrade} user = {user} />
        </div>
      </div>
    </div>
  );
};

export default CartPayment;
