import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TopBar from "./components/TopBar/Topbar";
import ProfilePage from "./pages/Profile/ProfilePage";
import LogoutPage from "./pages/Logout";
import SettingsPage from "./pages/Settings";
import StickersPage from "./pages/Stickers/StickersPage";
import InventoryPage from "./pages/Inventory/InventoryPage";
import ItemDetailPage from "./pages/Inventory/ItemDetailPage";
import CheckoutCart from "./pages/Checkout/CheckoutPage";
import "./App.css";
import AdBanner from "./components/TopBar/AdBanner";
import HomePage from "./pages/HomePage/HomePage";

const Router = ({ user, fetchUser }) => {
  const routeConfig = [
    { path: "/", page: <HomePage />, requiresAuth: false },
    { path: "/Home", page: <HomePage />, requiresAuth: false },
    {
      path: "/Login",
      page: user ? <Navigate to="/" /> : <HomePage />,
      requiresAuth: false,
    },
    {
      path: "/Profile",
      page: <ProfilePage user={user} fetchUser={fetchUser} />,
      requiresAuth: true,
    },

    { path: "/Logout", page: <LogoutPage />, requiresAuth: false },

    {
      path: "/canvas",
      page: <StickersPage user={user} />,
      requiresAuth: false,
    },
    {
      path: "/checkout",
      page: <CheckoutCart user={user} />,
      requiresAuth: true,
    },
  ];

  const ProtectedRoute = ({ page, requiresAuth }) => {
    if (requiresAuth && !Object.keys(user).length) {
      // Check if user is empty object
      return <Navigate to="/Login" />;
    }
    return page;
  };

  return (
    <BrowserRouter>
      <div>
        <AdBanner />
        <TopBar user={user} />
        <Routes>
          {routeConfig.map(({ path, page, requiresAuth }) => (
            <Route
              key={path}
              path={path}
              element={
                <ProtectedRoute page={page} requiresAuth={requiresAuth} />
              }
            />
          ))}
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default Router;
