import React, { useState, useEffect, useRef } from "react";
import "./OrderPaymentBox.css";
import Checkout from "./CheckoutWrapper";
import { useSelector, useDispatch } from "react-redux";
import PaymentBoxBalanceSection from "./PaymentBoxBalanceSection";
import { setBalance } from "../../../redux/slices/userSlice";
import {
  setIsTopup,
  setTopupAmount,
  showBalancePopup,
} from "../../../redux/slices/balancePopupSlice";

const OrderPaymentBox = ({ onProceedToSteamTrade, user }) => {
  const calculateDisplayCurrencyPrice = (price, user) => {
    return (price * user.currency_multiplier).toFixed(2);
  };

  const [clientSecret, setClientSecret] = useState("");
  const orderID = useSelector((state) => state.cart_session.current_order_id);

  const redux_cart = useSelector((state) => state.cart_session);
  const cartItems = redux_cart.cart_items;
  const totalQuantity = redux_cart.cart_items.length;
  const currentBalance = useSelector(state => state.user.balance);

  const [totalPrice, setTotalPrice] = useState(0.0);

  const dispatch = useDispatch();

  useEffect(() => {
    // Calculate total quantity and total price
    let totalPrice = 0.0; // Initialize totalPrice as a float
    cartItems.forEach((item) => {
      totalPrice += parseFloat(item.item_price);
    });

    setTotalPrice(totalPrice); // Round to 2 decimal places and set as string
  }, [cartItems]);

  const payOrder = () => {
    // Create PaymentIntent as soon as the page loads
    fetch(process.env.REACT_APP_API_URL + "auth/checkout/buyItems ", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Credentials": true,
      },
      body: JSON.stringify({
        items: cartItems,
      }),
    }).then((response) => {
      if (response.status === 200) 
        return response.json().then(resObject =>{
          dispatch(setBalance(currentBalance-totalPrice))
          onProceedToSteamTrade(resObject.order);
      })
      else if (response.status === 409)
        return response.json().then(errorData => {
          
          dispatch(setIsTopup());
          dispatch(setTopupAmount({ amount: errorData.topupAmount }));
      
          dispatch(showBalancePopup());
          console.log('Conflict Error:', errorData.message || 'Conflict error occurred');
          // Handle the error data as needed
        });
      else if (response.status === 500)
        return console.log("Error trying to create mock payment");
      console.log(response);
    });
  };
  return (
    <div className="payment-checkout-container">
      <div className="payment-checkout-box">
        <div className="payment-checkout-title">Pay your order</div>

        <div
          className="payment-checkout-horizontalDiv"
          style={{ marginBottom: "10px" }}
        >
          <div className="payment-checkout-infoText">TOTAL</div>
          <div className="payment-checkout-totalText">
            {user.display_currency}{" "}
            {calculateDisplayCurrencyPrice(totalPrice, user)}
          </div>
        </div>
        <div className="payment-checkout-divider-info"></div>
        <div className="payment-checkout-current-account-balance">
          Current Account Balance
        </div>

        <PaymentBoxBalanceSection  totalprice={totalPrice}/>
        <div className="payment-checkout-divider-info2"></div>

        <button
          className={`payment-checkout-button-proceed`}
          onClick={payOrder}
        >
          PAY ORDER
        </button>
      </div>
    </div>
  );
};

export default OrderPaymentBox;
