import React, { useState, useEffect } from "react";
import { addItem, makeStockPopupHidden, showCartDropDown } from "../../../redux/slices/cartSessionSlice";
import "./ChooseStickerPopupModal.css";
import { useDispatch, useSelector } from "react-redux";

const ModalStickerStockCell = ({ name, price, img, listing_id, reserved, reservedUntill, steam_price }) => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart_session.cart_items);
  
  // Check if the item is already in the cart
  const itemInCart = cartItems.some((item) => item.listing_id === listing_id);

  const addStickerToReduxCart = () => {
    dispatch(
      addItem({
        item_name: name,
        item_price: price,
        item_image_src: img,
        listing_id: listing_id,
        steam_price: steam_price,
      })
    );

    dispatch(makeStockPopupHidden());
    dispatch(showCartDropDown());
  };

  return (
    <div className="sticker-modal-cell">
      <img src={img} alt={"Sticker " + name} className="sticker-image" />
      <div className="sticker-modal-info">
        <div className="sticker-modal-title">
          {name}
          {reserved && <p style={{ color: "red" }}>Reserved until {reservedUntill}</p>}
        </div>
        <div className="sticker-modal-subtitle-container">
          <div className="sticker-modal-subtitle">{"EUR " + price}</div>
          {price < steam_price && (
            <div className="sticker-modal-discountbox">
              <p>{"-" + Math.round((price / steam_price) * 100) + "%"}</p>
            </div>
          )}
        </div>
      </div>
      {itemInCart ? (
          <button
          className="sticker-modal-already-in-cart-button"
        >
        Already in cart
        </button>
      ) : (
        <button
        className="sticker-modal-add-to-cart-button"
        onClick={addStickerToReduxCart}
      >
          ADD TO CART
      </button>
      )}

    </div>
  );
};

const ChooseStickerPopupModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const internalItemID = useSelector((state) => state.popupData.popup_data.internal_id);
  const itemName = useSelector((state) => state.popupData.popup_data.name);
  const image_path = useSelector((state) => state.popupData.popup_data.image_path);
  const [allsticker, setAllSticker] = useState([]);

  const fetchItems = async () => {
    const authURL = process.env.REACT_APP_API_URL + "auth/getAllListingsFromId";

    try {
      const response = await fetch(authURL, {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
        body: JSON.stringify({ itemId: internalItemID }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        setAllSticker(data.stickers);
      } else if (response.status === 404) {
        console.error("User not found");
      } else {
        throw new Error("Authentication failed");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (internalItemID !== undefined) {
      fetchItems();
    }
  }, [internalItemID]);

  const redux_cart = useSelector((state) => state.cart_session);

  if (!redux_cart.cart_stock_popup_visible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close-button" onClick={onClose}>
          &times;
        </button>
        <p className="choose-sticker-popup-title">We have multiple in stock</p>
        <p className="choose-sticker-popup-subtitle">
          There are currently {allsticker.length} listings for Sticker {itemName}.
        </p>
        <div className="sticker-modal-cell-wrapper">
          {allsticker.map((item, index) => (
            <div key={index}>
              <ModalStickerStockCell
                name={itemName}
                price={item.price}
                img={image_path}
                listing_id={item.id}
                reserved={item.isReserved}
                reservedUntill={item.reserved_until}
                steam_price={item.steam_price}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChooseStickerPopupModal;
