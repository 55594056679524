import React, { useRef, useState, useEffect } from "react";
import "../ProfilePage.css";
import { NavLink } from "react-router-dom";

const FieldSection = ({ user, title, content, canEdit, updateSetting }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);

  const handleInputChange = (event) => {
    setEditedContent(event.target.value);
  };

  const handleSave = () => {
    updateSetting(title, editedContent);
    setIsEditing(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (
        title != "Trade Link" ||
        (editedContent.includes(
          "https://steamcommunity.com/tradeoffer/new/?partner="
        ) &&
          title === "Trade Link")
      ) {
        handleSave();
      }
    }
  };

  return (
    <div>
      <div className="personal-details-field">
        <div
          className={`personal-details-field-left ${isEditing ? "editing" : ""
            }`}
          onClick={() => {
            canEdit ? setIsEditing(true) : setIsEditing(false);
          }}
        >
          <p className="field-title">{title}: </p>
          {isEditing ? (
            <input
              className="personal-details-input-field-edit"
              type="text"
              value={editedContent}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              autoFocus
            />
          ) : (
            <p className="field-info">{editedContent}</p>
          )}
        </div>
        {canEdit ? (
          <>
            <span className="personal-details-field-right">
              {isEditing ? (
                <>
                  {title != "Trade Link" ||
                    (editedContent.includes(
                      "https://steamcommunity.com/tradeoffer/new/?partner="
                    ) &&
                      title === "Trade Link") ? (
                    <>
                      <p
                        className="edit-text-apply-change"
                        style={{ color: "white", cursor: "pointer" }}
                        onClick={handleSave}
                      >
                        APPLY CHANGE
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <img
                  src="images/edit.png"
                  alt="Profile"
                  className="edit-image"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsEditing(true)}
                />
              )}
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="field-divider"></div> {/* Horizontal Divider */}
    </div>
  );
};

const prettyField = ({ string_input }) => {
  console.log(prettyField)
  if (string_input == null) {
    return "Empty Field";
  }
  else return string_input;
}

const PersonalDetailSection = ({ user, updateSetting }) => {


  return (
    <div className="personal-details-content-section">
      <div className="personal-details-title">
        <p className="personal-details-title-text">PERSONAL DETAILS</p>
      </div>
      <div className="personal-details-content">
        {/* Your content goes here */}

        <div className="personal-details-content-header">
          <div className="personal-details-avatar">
            <img src={user.avatar} alt="Profile" className="avatar-image" />
          </div>
          <div className="personal-details-info">
            <p className="personal-details-text">{user.steam_name}</p>

            <NavLink className="dropdown-filter-search" to="/logout">
              <button className="personal-details-button">SIGN OUT</button>
            </NavLink>
          </div>
        </div>

        <div className="personal-details-fields-wrapper">
          <FieldSection
            user={user}
            title={"Email"}
            content={user.email}
            canEdit={true}
            updateSetting={updateSetting}
          />
          <FieldSection
            user={user}
            title={"Phone"}
            content={user.phonenumber === 0 ? "" : user.phonenumber}
            canEdit={true}
            updateSetting={updateSetting}
          />
          <FieldSection
            user={user}
            title={"Address"}
            content={user.address}
            canEdit={true}
            updateSetting={updateSetting}
          />
          <FieldSection
            user={user}
            title={"ZIP"}
            content={user.zip === 0 ? "" : user.zip}
            canEdit={true}
            updateSetting={updateSetting}
          />
          <FieldSection
            user={user}
            title={"City"}
            content={user.city}
            canEdit={true}
            updateSetting={updateSetting}
          />
          <FieldSection
            user={user}
            title={"Country"}
            content={prettyField(user.country)}
            canEdit={true}
            updateSetting={updateSetting}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalDetailSection;
