// CartCell.js
import React from "react";
import QuantitySelector from "./QuantitySelector";
import "./Cart.css";
import { useDispatch } from "react-redux";
import { removeItem } from "../../../redux/slices/cartSessionSlice";

const calculateDisplayCurrencyPrice = (price, user) => {
  return (price * user.currency_multiplier).toFixed(2);
};

const CartCell = ({
  name,
  desc,
  img,
  price,
  quantity,
  onQuantityChange,
  user,
}) => {
  const dispatch = useDispatch();
  const removeItemFromStock = () => {
    dispatch(removeItem({ listing_id: 4 }));
  };

  console.log("image: ", img);
  return (
    <div className="cart-cell">
      <div className="left-section">
        <img src={img} alt="Sticker Image" className="sticker-image" />
      </div>
      <div className="mid-section">
        <p className="vertical-text">Sticker</p>
        <p className="vertical-text">{name}</p>
        <p className="vertical-text">{desc}</p>
      </div>
      <div className="right-section">
        <div className="vertical-stack">
          <div
            className="remove-from-cart-button-popup"
            onClick={removeItemFromStock}
          >
            <img
              src={"images/cross.png"}
              alt="Remove"
              className="remove-icon"
              onClick={() => {
                // Implement the remove item functionality here
                // Example: onRemoveItem(itemId);
              }}
            />
          </div>
          <p className="cart-right-text">
            {user.display_currency} {calculateDisplayCurrencyPrice(price, user)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CartCell;
