import React, { useRef, useState, useEffect } from "react";
import "../ProfilePage.css";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrencyMultiplier,
  setDisplayCurrency,
} from "../../../redux/slices/userSlice";

const FieldSection = ({ user, title, content, canEdit, updateSetting }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(content);

  const handleInputChange = (event) => {
    setEditedContent(event.target.value);
  };

  const handleSave = () => {
    updateSetting(title, editedContent);
    setIsEditing(false);
    // You might also want to update the content on the server or in a higher state here
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (
        title != "Trade Link" ||
        (editedContent.includes(
          "https://steamcommunity.com/tradeoffer/new/?partner="
        ) &&
          title === "Trade Link")
      ) {
        handleSave();
      }
    }
  };

  return (
    <div>
      <div className="personal-details-field">
        <div
          className={`personal-details-field-left ${
            isEditing ? "editing" : ""
          }`}
          onClick={() => {
            canEdit ? setIsEditing(true) : setIsEditing(false);
          }}
        >
          <p className="field-title">{title}: </p>
          {isEditing ? (
            <input
              className="personal-details-input-field-edit"
              type="text"
              value={editedContent}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              autoFocus
            />
          ) : (
            <p className="field-info">{editedContent}</p>
          )}
        </div>
        {canEdit ? (
          <>
            <span className="personal-details-field-right">
              {isEditing ? (
                <>
                  {editedContent.includes(
                    "https://steamcommunity.com/tradeoffer/new/?partner="
                  ) && title === "Trade Link" ? (
                    <>
                      <p
                        className="edit-text-apply-change"
                        style={{ color: "white", cursor: "pointer" }}
                        onClick={handleSave}
                      >
                        APPLY CHANGE
                      </p>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <img
                  src="images/edit.png"
                  alt="Profile"
                  className="edit-image"
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsEditing(true)}
                />
              )}
            </span>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="field-divider"></div> {/* Horizontal Divider */}
    </div>
  );
};

const CurrencySelectorSection = ({ user, updateSetting }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState(
    user.display_currency
  );
  const dispatch = useDispatch();

  const title = "Display Currency";

  const handleSave = () => {
    setIsEditing(false);
    // You might also want to update the content on the server or in a higher state here
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSave();
    }
  };

  const handleCurrencySelect = (currency, event) => {
    event.preventDefault(); // Prevent default behavior

    updateSetting("display_currency", currency);
    setSelectedCurrency(currency);
    setIsEditing(false);
    dispatch(setDisplayCurrency(currency)); // update redux state

    const exchange_rate = user.currency_exchange[currency];
    dispatch(setCurrencyMultiplier(exchange_rate));
  };

  return (
    <div className="currency-selector-section">
      <div className="personal-details-field">
        <div
          className={`personal-details-field-left ${
            isEditing ? "editing" : ""
          }`}
          onClick={() => setIsEditing(true)}
        >
          <p className="field-title">{title}: </p>
          <p className="currency-selector-title">{selectedCurrency}</p>
        </div>
        <div className="currency-selector-field-right">
          {isEditing ? (
            <div />
          ) : (
            <img
              src="images/edit.png"
              alt="Profile"
              className="edit-image"
              onClick={() => setIsEditing(true)}
            />
          )}
        </div>
      </div>

      {isEditing && (
        <div className="currency-selector-popup">
          <div
            className="currency-selector-cell"
            onClick={(event) => handleCurrencySelect("SEK", event)}
          >
            <p className="currency-selector-cell-title">SEK</p>
          </div>
          <div
            className="currency-selector-cell"
            onClick={(event) => handleCurrencySelect("EUR", event)}
          >
            <p className="currency-selector-cell-title">EUR</p>
          </div>
          <div
            className="currency-selector-cell"
            onClick={(event) => handleCurrencySelect("USD", event)}
          >
            <p className="currency-selector-cell-title">USD</p>
          </div>
          <div
            className="currency-selector-cell"
            onClick={(event) => handleCurrencySelect("NOK", event)}
          >
            <p className="currency-selector-cell-title">NOK</p>
          </div>
          <div
            className="currency-selector-cell"
            onClick={(event) => handleCurrencySelect("DKK", event)}
          >
            <p className="currency-selector-cell-title">DKK</p>
          </div>

          <div className="currency-selector-info-section">
            <p className="currency-selector-info-section-text">
              All transactions are made in EUR. These currencies are for display
              only.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const GeneralInfoSection = ({ user, updateSetting }) => {
  console.log(user);
  return (
    <div className="general-information-content-section">
      <div className="general-information-title">
        <p className="general-information-title-text">GENERAL INFORMATION</p>
      </div>
      <div className="general-information-content">
        {/* Your content goes here */}

        <div className="general-information-fields-wrapper">
          <FieldSection
            user={user}
            title={"Trade Link"}
            content={user.tradeurl}
            canEdit={true}
            updateSetting={updateSetting}
          />
          <FieldSection
            user={user}
            title={"SteamID64"}
            content={user.steam64id}
            canEdit={false}
            updateSetting={updateSetting}
          />
          <CurrencySelectorSection user={user} updateSetting={updateSetting} />
        </div>
      </div>
    </div>
  );
};

export default GeneralInfoSection;
