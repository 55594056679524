import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setIsNotTopup,
  showBalancePopup,
} from "../../redux/slices/balancePopupSlice";

const calculateDisplayCurrencyPrice = (price, user) => {
  return (price * user.currency_multiplier).toFixed(2);
};

const BalanceSection = () => {
  const reduxUser = useSelector((state) => state.user);
  const [displayBalance, setDisplayBalance] = useState(
    calculateDisplayCurrencyPrice(reduxUser.balance, reduxUser)
  );

  useEffect(() => {
    setDisplayBalance(
      calculateDisplayCurrencyPrice(reduxUser.balance, reduxUser)
    );
  }, [reduxUser]);

  const dispatch = useDispatch();

  const displayBalancePopup = () => {
    dispatch(setIsNotTopup());

    dispatch(showBalancePopup());
  };

  return (
    <div
      className="top-bar-balance"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginRight: "16px",
        paddingLeft: "12px",
        height: "36px",
        minWidth: "160px",
        textAlign: "right", // Ensure text alignment to the right
      }}
      onClick={displayBalancePopup}
    >
      <div
        style={{
          display: "inline-block", // Adjusts size based on content
          minWidth: "100px", // Minimum width of the box
          padding: "0 8px", // Padding inside the box
          whiteSpace: "nowrap", // Prevent text wrapping
        }}
      >
        <p
          style={{
            margin: 0, // Remove default margin
            fontFamily: "'Kanit', sans-serif",
            textAlign: "right", // Align text to the right
            color: "#fff", // Text color
            marginRight: "7px",
          }}
        >
          {reduxUser.display_currency} {displayBalance}
        </p>
      </div>
      <div
        style={{
          width: "45px",
          height: "36px",
          backgroundColor: "#2E8FFF",
          padding: "7px",
        }}
      >
        {/* Image for plus icon */}
        <img
          src="/images/plus.png"
          alt="Add_Icon"
          style={{
            width: "20px",
            height: "20px",
            objectFit: "contain",
            marginTop: "1px",
            marginLeft: "6px",
          }}
        />
      </div>
    </div>
  );
};

export default BalanceSection;
