import "./CartSteamTrade.css"; // Import CSS file for styling

const SteamTradeBotIconCell = ({sender_avatar}) => {
    // Same width as height and should contain image
    return (
      <div className="steam-trade-bot-icon-cell-wrapper">
        <img
          src={sender_avatar}
          alt="Item"
          className="steam-trade-cell-image-bot-icon"
        />
      </div>
    );
  };
  
  const SteamTradeInfoCell = ({ bot_name, bot_created_date }) => {
    // Double width as height and texts should be stacked vertically
    return (
      <div className="steam-trade-info-cell-wrapper">
        <p style={{ fontSize: "18px", fontWeight: "800" }}>{bot_name}</p>
        <p style={{ fontWeight: 400 }}>Registered: {bot_created_date}</p>
      </div>
    );
  };
  
  const SteamTradeItemCell = ({ image_link }) => {
    // Same width as height and should contain image
    return (
      <div className="steam-trade-item-cell-wrapper">
        <img src={image_link} alt="Item" className="steam-trade-cell-image" />
      </div>
    );
  };
  
  const SteamTradeItemCellEmpty = () => {
    // Same width as height and should contain image
    return <div className="steam-trade-item-cell-empty-wrapper"></div>;
  };
  
  const AcceptTradeButtonCell = ({trade_status}) => {

    const TradeStatusBox = () => {
      if(trade_status === "Offer not sent"){
        return <p className="order-trades-steam-trade-status-offer-not-sent-box">Trade not yet sent</p>;
      }
      if (trade_status === "Accepted") {
        return <p className="steam-trade-status-completed-box">{trade_status}</p>;
      } else if (trade_status === "Active") {
        return (  
        <button
          className="accept-trade-button"
          onClick={() => window.open('https://steamcommunity.com/my/tradeoffers/', '_blank')}
        >
          ACCEPT ON STEAM
        </button>
        )
      } else if (trade_status === "Declined") {
        return <p className="steam-trade-status-failed-box">{trade_status}</p>;
      }
    };
    return (
      <div className="accept-trade-button-cell-wrapper">
        <TradeStatusBox />

  
  
      </div>
    );
  };
  
  const TradeIconCell = () => {
    // Same width as height and should contain image
    return (
      <div className="steam-trade-bot-trade-icon-cell-wrapper">
        <img
          src="images/trade_icon.png"
          alt="Item"
          className="steam-trade-bot-trade-icon-cell-image"
        />
      </div>
    );
  };
  
  const TradingBotDisplay = ({
    bot_name,
    bot_created_date,
    send_items,
    receive_items,
    sender_avatar,
    trade_status,
  }) => {
    // Add one empty cell if send_items or receive_items are empty
    const adjustedSendItems = send_items.length === 0 ? [""] : send_items;
    const adjustedReceiveItems =
      receive_items.length === 0 ? [""] : receive_items;
  
    return (
      <div className="steam-trade-page-bot-cell">
        <div className="grid-container-bot-item-cells">
          <SteamTradeBotIconCell sender_avatar={sender_avatar}/>
  
          <SteamTradeInfoCell
            bot_name={bot_name}
            bot_created_date={bot_created_date}
          />
  
          {adjustedSendItems.map((item, index) =>
            item ? (
              <SteamTradeItemCell key={index} image_link={process.env.REACT_APP_STICKER_URL+item.item_name+".png"} />
            ) : (
              <SteamTradeItemCellEmpty key={index} />
            )
          )}
  
          <TradeIconCell />
  
          {adjustedReceiveItems.map((item, index) =>
            item ? (
              <SteamTradeItemCell key={index} image_link={process.env.REACT_APP_STICKER_URL+item.item_name+".png"} />
            ) : (
              <SteamTradeItemCellEmpty key={index} />
            )
          )}
  
          <AcceptTradeButtonCell trade_status={trade_status}/>
        </div>
      </div>
    );
  };

  
export default TradingBotDisplay;