import React, { useState } from "react";

const RangeInputFieldDropdown = ({
  name,
  from_placeholder,
  to_placeholder,
  onFilterChange,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [fromValue, setFromValue] = useState("");
  const [toValue, setToValue] = useState("");
  const [isFromValid, setIsFromValid] = useState(true);
  const [isToValid, setIsToValid] = useState(true);

  // Adjust the toggle function to manually control the visibility
  const handleToggle = (e) => {
    e.preventDefault(); // Prevent the default details tag toggle behavior
    setIsOpen(!isOpen);
  };

  const handleFromChange = (e) => {
    if(e.target.value === ""){
      if(toValue === ""){
        setIsToValid(true);
        setIsFromValid(true);
        setFromValue(e.target.value);
      }else{
      setFromValue(e.target.value);
      setIsFromValid(false);
    }
    }else if(toValue !== ""){
    setIsToValid(true);
    setIsFromValid(true);
    setFromValue(e.target.value);
    onFilterChange(e.target.value, toValue);
  }else{
    setFromValue(e.target.value);
    setIsToValid(false);
  }
  };

  const handleToChange = (e) => {
    if(e.target.value === ""){
      if(fromValue === ""){
        setIsToValid(true);
        setIsFromValid(true);
        setToValue(e.target.value);
      }else{
      setToValue(e.target.value);
      setIsToValid(false);
      }
    }else if(fromValue !== ""){
      setIsFromValid(true);
      setIsToValid(true);
      setToValue(e.target.value);
      onFilterChange(fromValue, e.target.value);
  }else{
      setToValue(e.target.value);
      setIsFromValid(false);
  }
  };

  return (
    <>
      <hr style={{ borderColor: "#171723", marginBottom: "10px" }} />
      <div style={{ marginBottom: "10px" }}>
        <summary
          onClick={handleToggle} // Use onClick instead of onToggle
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            listStyle: "none",
            userSelect: "none",
          }}
        >
          <span style={{ marginBottom: "5px" }}>{name}</span>
          <span style={{ userSelect: "none", marginRight: "10px" }}>
            {isOpen ? "-" : "+"}
          </span>
        </summary>
        {isOpen && ( // Conditional rendering based on isOpen state
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "5px 0",
            }}
          >
            <input
              type="text"
              placeholder={from_placeholder}
              value={fromValue}
              onChange={handleFromChange}
              style={{
                width: "calc(50% - 10px)",
                padding: "8px",
                marginRight: "10px",
                border: `1px solid ${isFromValid ? "#fff" : "red"}`,
                backgroundColor: "#232335",
                color: "#fff",
                fontSize: "10px",
              }}
            />
            <input
              type="text"
              placeholder={to_placeholder}
              value={toValue}
              onChange={handleToChange}
              style={{
                width: "calc(50% - 10px)",
                padding: "8px",
                border: `1px solid ${isToValid ? "#fff" : "red"}`,
                backgroundColor: "#232335",
                color: "#fff",
                fontSize: "10px",
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default RangeInputFieldDropdown;
