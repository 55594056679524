import React from "react";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import "./TopBar.css";
import AddBalancePopupModal from "../PopupModals/AddBalancePopupModal/AddBalancePopupModal";

const TopBar = ({ user }) => {
  const topBarStyle = {
    width: "100%",
    height: "55px",
    backgroundColor: "#171723",
    color: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 20px",
  };

  return (
    <div style={topBarStyle} className="topbar-wrapper">
      <LeftSection />
   
      <RightSection user={user} />
      <AddBalancePopupModal />

    </div>
  );
};

export default TopBar;
