import React from "react";
import "./CartItemBox.css";
import QuantitySelector from "../../TopBar/cart/QuantitySelector";

const calculateDisplayCurrencyPrice = (price, user) => {
  return (price * user.currency_multiplier).toFixed(2);
};

const CheckoutCartCell = ({
  name,
  desc,
  img,
  price,
  quantity,
  onQuantityChange,
  user,
}) => {
  return (
    <div className="cart-cell-container">
      <div className="cart-cell-left">
        <img src={img} alt="titan" className="cart-cell-image" />
      </div>
      <div className="cart-cell-mid">
        <p className="cart-cell-text">Sticker</p>

        <p className="cart-cell-text">{name}</p>
        <p className="cart-cell-text">{desc}</p>
      </div>
      <div className="cart-cell-right">
        <div className="cart-cell-stack">
          <p className="cart-cell-price">
            {user.display_currency} {calculateDisplayCurrencyPrice(price, user)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CheckoutCartCell;
