import React, { useState } from "react";
import "../CheckoutPage.css"; // Import CSS file for styling

const CartComplete = () => {
  return (
    <div style={{ backgroundColor: "green", height: "100%", width: "100%" }}>
      <p>Step 4: Order Complete!</p>
    </div>
  );
};

export default CartComplete;