import React, { useState, useEffect } from "react";
import CartCell from "./CartCell";
import "./Cart.css";
import { useCart } from "./CartContext";
import { NavLink } from "react-router-dom";
import EmptyCartCell from "./EmptyCartCell";
import { useSelector } from "react-redux";
import {
  removeAllItems,
  hideCartDropDown,
} from "../../../redux/slices/cartSessionSlice";
import { useDispatch } from "react-redux";
const CartPopup = ({ cartDetailsRef, cellCount, setCellCount, user }) => {
  const redux_cart = useSelector((state) => state.cart_session);

  const dispatch = useDispatch();
  const [totalPrice, setTotalPrice] = useState(0);

  const HandleEmptyCart = () => {
    // clearCart();

    dispatch(removeAllItems());
  };

  const calculateDisplayCurrencyPrice = (price, user) => {
    return (price * user.currency_multiplier).toFixed(2);
  };

  useEffect(() => {
    // Calculate total quantity and total price
    let totalPrice = 0.0; // Initialize totalPrice as a float

    redux_cart.cart_items.forEach((item) => {
      // Remove "SEK " from the string
      totalPrice += parseFloat(item.item_price);

    });

    setTotalPrice(totalPrice); // Round to 2 decimal places and set as string
  }, [redux_cart.cart_items]);


  return (
    <div
      ref={cartDetailsRef}
      className="cart-popup"
      style={{
        position: "absolute",
        top: "49px",
        left: "-80px",
        transform: "translateX(-50%)",
        zIndex: "8",
        width: "300px",
        minHeight: "400px",
        backgroundColor: "#fff",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
        display: "flex",
        flexDirection: "column",
        maxHeight: "calc(100vh - 49px)",
      }}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <div
        style={{
          minHeight: "50px",
          backgroundColor: "#171723",
          boxShadow: "0px 10px 40px rgba(0, 0, 0, 0.6)",
        }}
      >
        <p
          style={{
            marginTop: "15px",
            marginLeft: "19px",
            fontFamily: "'Kanit', sans-serif",
          }}
        >
          <div className="subTitle">
            {redux_cart.cart_items.length}{" "}
            {redux_cart.cart_items.length === 1 ? "ITEM" : "ITEMS"} IN CART
          </div>
        </p>
      </div>
      <div
        className="cell-wrapper-cart"
        style={{
          flex: 1,
          overflowY: "auto",
          backgroundColor: "#171723",
          paddingBottom: "20px",
        }}
      >
        {redux_cart.cart_items.length === 0 ? (
          <EmptyCartCell />
        ) : (
          redux_cart.cart_items
            .slice()
            .reverse()
            .map(
              (
                item // Reverse the array before mapping
              ) => (
                <div key={item.id} style={{ borderTop: "1px solid #1F1F2F" }}>
                  <CartCell
                    img={item.item_image_src}
                    name={item.item_name}
                    price={item.item_price}
                    user={user}
                  />
                </div>
              )
            )
        )}
      </div>

      {redux_cart.cart_items.length > 0 && (
        <div
          style={{
            minHeight: "200px",
            backgroundColor: "#171723",
            boxShadow: "0px -10px 40px rgba(0, 0, 0, 0.3)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: "33%",
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 20px",
              marginTop: "10px",
            }}
          >
            <span
              style={{
                fontFamily: "sans-serif",
                color: "white",
                fontWeight: 800,
                fontSize: "15px",
              }}
            >
              TOTAL
            </span>
            <span
              style={{
                fontFamily: "sans-serif",
                color: "white",
                fontWeight: 400,
                fontSize: "15px",
              }}
            >
              {user.display_currency}{" "}
              {calculateDisplayCurrencyPrice(totalPrice, user)}
            </span>
          </div>
          <div
            style={{
              height: "33%",
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NavLink to="/checkout">
              <button
                style={{
                  color: "white",
                  fontFamily: "'Kanit', sans-serif",
                  border: "none",
                  padding: "12px 50px",
                  cursor: "pointer",
                  backgroundClip: "padding-box",
                  backgroundColor: "#2E8FFF",
                  fontSize: "12px",
                  marginTop: "30px",
                }}
              >
                CONTINUE TO CHECKOUT
              </button>
            </NavLink>
          </div>
          <button
            onClick={HandleEmptyCart}
            style={{
              height: "33%",
              flex: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontFamily: "'Kanit', sans-serif",
              fontSize: "12px",
              marginBottom: "20px",
            }}
          >
            CLEAR CART
          </button>
        </div>
      )}
    </div>
  );
};

export default CartPopup;
