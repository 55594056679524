import React, { useState } from "react";
import "../../../App.css";
import CheckboxDropdown from "./Dropdowns/Checkbox/CheckboxDropdown";
import InputFieldDropdown from "./Dropdowns/Input/InputFieldDropdown";
import RangeInputFieldDropdown from "./Dropdowns/Range/RangeInputFieldDropdown";
import "./MarketSideBar.css";

import { useSelector } from "react-redux";

const MarketSideBar = ({ setCollection }) => {
  const redux_cart = useSelector((state) => state.cart_session);

  // Import capsules data here or fetch it from an API
  const capsules = require("./Dropdowns/Input/capsules.json");
  const items = capsules.capsules;

  // State to store the selected item
  const [selectedItem, setSelectedItem] = useState(null);

  const handlePriceFilterChange = (from, to) => {
    // Pass the current collection to update it selectively
    setCollection(0, from + ":" + to);
  };

  const handleTypeFilterChange = (item) => {
    // Pass the current collection to update it selectively
    setCollection(1, item);
  };

  const handleColorFilterChange = (item) => {
    // Pass the current collection to update it selectively
    setCollection(2, item);
  };
  const handleshowOnlyInStockChange = (item) => {
    console.log(item);
    setCollection(3, item);
  };

  // Function to handle when an item is selected
  const handleSelectItem = (item) => {
    console.log(item);
    setSelectedItem(item);
    // Do any additional logic you need here
  };
  return (
    <div className="market-sidebar-container">
      <>
        <div style={{ marginTop: "10px" }}>
          {redux_cart.cart_enabled ? (
            <div>
              <CheckboxDropdown
                name="General"
                options={["Only In Stock"]}
                keys={["true"]}
                onFilterChange={handleshowOnlyInStockChange}
                defaultOpen={true}
                className="checkbox-dropdown"
                deafaultCheckboxvalue={false}
              />

              <RangeInputFieldDropdown
                name="Price"
                from_placeholder={"From"}
                to_placeholder={"To"}
                onFilterChange={handlePriceFilterChange}
                defaultOpen={true}
                className="range-input-dropdown"
              />
            </div>
          ) : (
            <div />
          )}
        </div>

        <CheckboxDropdown
          name="Type"
          options={["Holo", "Glitter", "Foil", "Gold"]}
          keys={["(Holo)", "(Glitter)", "(Foil)", "(Gold)"]}
          onFilterChange={handleTypeFilterChange}
          defaultOpen={true}
          className="checkbox-dropdown"
          
        />
   {/*      <CheckboxDropdown
          name="Color"
          options={[
            "Red",
            "Blue",
            "Yellow",
            "Green",
            "Orange",
            "Purple",
            "Black",
            "white",
          ]}
          keys={[
            "Red",
            "Blue",
            "Yellow",
            "Green",
            "Orange",
            "Purple",
            "Black",
            "white",
          ]}
          onFilterChange={handleColorFilterChange}
          defaultOpen={true}
          className="checkbox-dropdown"
        /> */}
      </>
    </div>
  );
};

export default MarketSideBar;
