// slices/DetailUserSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const stickerFetchingSlice = createSlice({
    name: 'stickerFetchingData',
    initialState: {
        search_query_performed: false,
        sticker_added_id: -1
    },
    reducers: {
        // Search query
        setSearchPerformed: (state) => {
            state.search_query_performed = true;
        },
        clearSearchPerformed: (state) => {
            state.search_query_performed = false;
        },

        // Sticker added to canvas
        setStickerAddedID: (state, action) => {
            state.sticker_added_id = action.payload;
        },
        clearStickerID: (state) => {
            state.sticker_added_id = -1;
        },
    },
});

export const { setSearchPerformed, clearSearchPerformed, setStickerAddedID, clearStickerID } = stickerFetchingSlice.actions;

export default stickerFetchingSlice.reducer;
