import React, { useState, useEffect } from "react";
import "./TabSystem.css"; // Import the CSS file

// redux
import { useDispatch, useSelector } from "react-redux";

import {
  requestRemoveAllMeshes
} from "../../../redux/slices/tabSystemSlice";


// Individual Tab Cell Component
const TabCell = ({
  tab,
  isSelected,
  onTabTextChange,
  onTabMove,
  onSelect,
  removeTab,
}) => {


  const [isEditing, setIsEditing] = useState(false);
  const [newTabText, setNewTabText] = useState("");

  const dispatch = useDispatch();


  useEffect(() => {
    if (!isEditing) {
      setNewTabText(tab.name);
    }
  }, [isEditing, tab.name]);

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    if (newTabText.length >= 6) {
      setIsEditing(false);
      onTabTextChange(tab.index, newTabText);
    } else {
      // Reset the input value if the text is not valid
      setNewTabText(tab.name);
      setIsEditing(false);
    }
  };

  const handleChange = (e) => {
    setNewTabText(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  const handleClick = () => {

    // remove all meshes
    // dispatch(requestRemoveAllMeshes())

    onSelect(tab.index);
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setData("text/plain", tab.index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const draggedTabIndex = parseInt(e.dataTransfer.getData("text/plain"));
    const droppedOnTabIndex = tab.index;

    if (draggedTabIndex === droppedOnTabIndex) {
      return;
    }

    onTabMove(draggedTabIndex, droppedOnTabIndex);
    onSelect(droppedOnTabIndex);
  };

  return (
    <div
      className={`tab-cell ${isSelected ? "selected" : ""}`}
      draggable={true}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      onClick={handleClick}
    >
      {isEditing ? (
        <input
          className="change-tab-name-input"
          type="text"
          value={newTabText}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      ) : (
        <p className="tab-system-name" onDoubleClick={handleDoubleClick}>
          {tab.name}
        </p>
      )}
      {removeTab && (
        <div className="cross-tab" onClick={removeTab}>
          <img src="images/cross.png" alt="Delete Tab" />
        </div>
      )}
    </div>
  );
};

export default TabCell;
