import React from "react";

const LoginButton = () => {
  return (
    <a href= {process.env.REACT_APP_API_URL + "auth/steam"} rel="noopener noreferrer">
      <img src="/images/sign_in_with_steam.png" alt="Link Icon" style={{ height: "30px" }} />
    </a>
  );
};

export default LoginButton;
