import React, { useState, useEffect } from "react";
import "../../../App.css";
import { useSelector, useDispatch } from "react-redux";
import { setSearchPerformed, clearSearchPerformed } from "../../../redux/slices/stickerFetchingSlice";


const StickerSearch = ({ setSearchQuery }) => {
  const [placeholderText, setPlaceholderText] = useState(
    "Search Sticker Marketplace"
  );

  const redux_cart = useSelector((state) => state.cart_session);
  const sticker_fetching = useSelector((state) => state.sticker_fetching);

  const dispatch = useDispatch();


  useEffect(() => {
    const updatePlaceholder = () => {
      if (redux_cart.cart_enabled) {
        setPlaceholderText(
          window.innerWidth <= 768 ? "Search Marketplace" : "Search Marketplace"
        );
      } else {
        setPlaceholderText("Search Stickers");
      }
    };

    updatePlaceholder();

    window.addEventListener("resize", updatePlaceholder);

    return () => window.removeEventListener("resize", updatePlaceholder);
  }, []);

  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Clear the previous timeout to avoid sending multiple requests
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout to send the search query after a delay
    const timeout = setTimeout(() => {
      setSearchQuery(value);
    }, 500); // Adjust the delay time here, 500 milliseconds is a common standard



    if (value == "") {
      dispatch(clearSearchPerformed());
    } else {
      dispatch(setSearchPerformed());
    }

    setTypingTimeout(timeout);
  };


  // useEffect(() => {
  //   console.log("redux", sticker_fetching)


  // }, [sticker_fetching])

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        className="search-bar"
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "50px",
          color: "white",
          padding: "0 10px",
        }}
      >
        <img
          src="/images/search.png"
          alt="Search"
          style={{
            width: "24px",
            height: "24px",
            marginRight: "2px",
          }}
        />
        <input
          type="text"
          onChange={handleInputChange}
          placeholder={placeholderText}
          style={{
            flexGrow: 1,
            backgroundColor: "transparent",
            border: "none",
            color: "white",
            outline: "none",
            marginLeft: "10px",
            fontSize: "12px",
          }}
        />
      </div>
    </div>
  );
};

export default StickerSearch;
