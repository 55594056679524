import { createSlice } from "@reduxjs/toolkit";

export const cartSessionSlice = createSlice({
  name: "cart_session",
  initialState: {
    cart_enabled: false,
    cart_items: [
      /*  {
        item_name: "Sticker | Unicorn (Foil)",
        item_price: 12.39,
        item_image_src: "images/stickers/unicorn.png",
        listing_id: 1,
      },
      {
        item_name: "Sticker | Titan (Holo)",
        item_price: 24.21,
        item_image_src: "images/stickers/titanholo.png",
        listing_id: 22,
      }, */
    ],
    cart_stock_popup_visible: false,
    cart_dropdown_visible: false,

    // checkout (-1 => Not Started)
    checkout_stage: 1,
    current_order_id: null,
    balance_popup_visible: true
  },
  reducers: {
    /**
     * Adds an item to the cart with the specified details
     * Adds only if the item is not already in the cart.
     *
     * Usage: dispatch(addItem({ item_name: 'Sticker | New', item_price: 15.00, item_image_src: 'images/stickers/new.png', listing_id: 4 }));
     */
    addItem: (state, action) => {
      const { item_name, item_price, item_image_src, listing_id } = action.payload;
      
      // Check if the item is already in the cart
      const itemExists = state.cart_items.some(item => item.listing_id === listing_id);
      
      // If the item does not exist, add it to the cart
      if (!itemExists) {
        state.cart_items.push({
          item_name,
          item_price,
          item_image_src,
          listing_id,
        });
      }
    },

    /**
     * Removes an item from the cart by its listing_id
     *
     * Usage: dispatch(removeItem({ listing_id: 1 }));
     */
    removeItem: (state, action) => {
      const { listing_id } = action.payload;
      state.cart_items = state.cart_items.filter(
        (item) => item.listing_id !== listing_id
      );
    },

    removeAllItems: (state) => {
      state.cart_items = []; // Clears all items
    },

    // stock popup
    makeStockPopupVisible: (state) => {
      state.cart_stock_popup_visible = true;
    },
    makeStockPopupHidden: (state) => {
      state.cart_stock_popup_visible = false;
    },

    // cart dropdown
    showCartDropDown: (state) => {
      if (state.checkout_stage <= 1) {
        state.cart_dropdown_visible = true;
      }
    },
    hideCartDropDown: (state) => {
      state.cart_dropdown_visible = false;
    },

    // checkout
    endCheckout: (state) => {
      state.checkout_stage = -1;
    },

    setCheckoutStage: (state, action) => {
      const { checkoutStage } = action.payload;
      state.checkout_stage = checkoutStage;
    },

    setOrderId: (state, action) => {
      const { orderId } = action.payload;
      state.current_order_id = orderId;
    },

    
  },
});

export const {
  addItem,
  removeItem,
  removeAllItems,
  makeStockPopupHidden,
  makeStockPopupVisible,
  showCartDropDown,
  hideCartDropDown,
  setCheckoutStage,
  setOrderId,
  endCheckout,
} = cartSessionSlice.actions;

export default cartSessionSlice.reducer;
