import React, { useEffect, useState,useRef } from "react";
import "./ProfilePage.css";
import PersonalDetailSection from "./sections/PersonalDetailSection";
import GeneralInfoSection from "./sections/GeneralInfoSection";
import TradingBotDisplay from "../Checkout/CartPages/CartSteamTrade/TradingBotDisplay";
import BalanceAndTransactionsTable from "./sections/BalanceAndTransactionsTable";


const GeneralInformationSection = () => {
  return (
    <div className="general-information-content-section">
      <div className="general-information-header">
        <p>GENERAL INFORMATION</p>
      </div>
      <div className="general-information-content">
      </div>
    </div>
  );
};

const updateSetting = async (setting, value) => {


  try {
    const response = await fetch(process.env.REACT_APP_API_URL + "auth/user/settings", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: setting,
        value: value,
      }),
    })

    if (!response.ok) {
      throw new Error("Failed to update setting");
    }
    else{
      console.log("Setting updated")
      console.log(setting, value)
    }
  } catch (error) {
    console.error(error.message);
  }

}

const AllTrades = () => {

  const [receivedItems,setReceivedItems] = useState([]); // Example receive items

  const getTrades = () => {
      // Create PaymentIntent as soon as the page loads
      fetch(process.env.REACT_APP_API_URL + "auth/fetchTrades", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 500)
            return console.log("Error trying to create mock payment");
          console.log(response);
        })
        .then((resObject) => {
          console.log(resObject.trades); // This should log the order ID
          if(resObject.success){
            setReceivedItems(resObject.trades)
          }
        });
    };
    
    useEffect(() => {
      getTrades();
    }, []);


  return (
    <div className="all-trades-section">
      <div className="all-trades-header">
        <p>Uncompleted trades *</p>
      </div>
      <div className="all-trades">
        {receivedItems.map((trade,index) => (
        <TradingBotDisplay key={index}
        bot_name={trade.steam_name}
        send_items={[{
          name: trade.steam_name,
          item_name: trade.name,
        }]}
        sender_avatar={trade.avatar}
        trade_status={trade.status}
        date_of_trade={trade.trade_date}
        receive_items={[]}
        />
        ))}
      </div>
    </div>
  );
};
const BalanceAndTransactions = () => {

  const [transactions,setTransactins] = useState([]); // Example receive items

  const getTransactions = () => {
      // Create PaymentIntent as soon as the page loads
      fetch(process.env.REACT_APP_API_URL + "auth/fetchTransactions", {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else if (response.status === 500)
            return console.log("Error trying to create mock payment");
          console.log(response);
        })
        .then((resObject) => {
          console.log(resObject.transactions);
          if(resObject.success){
            setTransactins(resObject.transactions)
          }
        });
    };
    
    useEffect(() => {
      getTransactions();
    }, []);
    const headers = [
      { key: "transactionID", label: "Transaction ID" },
      { key: "type", label: "Type" },
      { key: "amount", label: "Amount (EUR)" },
      { key: "date_time", label: "Date" },
      { key: "status", label: "Status" },
    ];

  return (
    <div className="balance-and-transactions-section">
      <div className="balance-and-transactions-header">
        <p>BALANCE AND TRANSACTIONS</p>
      </div>
      <div className="balance-and-transactions">
      <BalanceAndTransactionsTable
        headers={headers}
        data={transactions}
        detailPage="users"
      />
      </div>
    </div>
  );
};

const ProfilePage = ({ user }) => {
  const personalDetailsRef = useRef(null);
  const generalInformationRef = useRef(null);
  const allTradesRef = useRef(null);
  const balanceTransactionsRef = useRef(null);

  const scrollToPersonalDetails = () => {
    personalDetailsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToGeneralInformation = () => {
    generalInformationRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToAllTrades = () => {
    allTradesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBalanceTransactions = () => {
    balanceTransactionsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="profile-page-wrapper">
      <div className="profile-page-sidebar">
        <button className="sidebar-button" onClick={scrollToPersonalDetails}>
          <img
            src="images/person.png"
            alt="Button Image"
            className="button-image"
          />
          <span className="button-text">PERSONAL DETAILS</span>
        </button>
        <button className="sidebar-button" onClick={scrollToGeneralInformation}>
          <img
            src="images/tools.png"
            alt="Button Image"
            className="button-image"
          />
          <span className="button-text">GENERAL INFORMATION</span>
        </button>
        <button
          className="sidebar-button"
          onClick={scrollToBalanceTransactions}
        >
          <img
            src="images/trade_icon.png" // images/transaction.png
            alt="Button Image"
            className="button-image"
          />
          <span className="button-text">ALL TRADES</span>
        </button>
        <button
          className="sidebar-button"
          onClick={scrollToAllTrades}
        >
          <img
            src="images/transaction.png"
            alt="Button Image"
            className="button-image"
          />
          <span className="button-text">BALANCE AND TRANSACTIONS</span>
        </button>
      </div>
      <div className="profile-page-content-box">
        {/* Content goes here */}

        <div
          className="personal-details-content-section"
          ref={personalDetailsRef}
        >
          <PersonalDetailSection user={user} updateSetting = {updateSetting} />
        </div>
        <div
          className="general-information-content-section"
          ref={generalInformationRef}
        >
          <GeneralInfoSection user={user} updateSetting = {updateSetting} />
        </div>
        <div
          className="all-trades-section"
          ref={allTradesRef}
        >
          <AllTrades />
        </div>
        <div
          className="balance-and-transactions-section"
          ref={balanceTransactionsRef}
        >
          <BalanceAndTransactions />
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
