import React, { useEffect, useRef, useState } from "react";
import UnSelectedMenuCell from "./UnSelectedMenuCell";

const UnSelectedRightClickMenu = ({ x, y, onCellClick, onClose }) => {
  const [position, setPosition] = useState({ left: x + 470, top: y - 100 });
  const menuRef = useRef(null);

  useEffect(() => {
    const menuWidth = 170; // Width of the menu
    const menuHeight = 134; // Height of the menu
    const topPadding = 123; // New top padding
    const bottomPadding = 20; // New bottom padding
    const rightPadding = 275; // New right padding

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Calculate the left position of the menu
    let newLeft = Math.min(x + 470, windowWidth - menuWidth - rightPadding);

    // Calculate the top position of the menu
    let newTop = Math.min(y - 100, windowHeight - menuHeight - bottomPadding);
    if (newTop < topPadding) {
      newTop = topPadding; // Ensure menu stays within top padding
    }

    // Check if menu is too close to bottom
    if (newTop + menuHeight > windowHeight - bottomPadding) {
      newTop = windowHeight - menuHeight - bottomPadding;
    }

    setPosition({ left: newLeft, top: newTop });
  }, [x, y]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // onClose(); // Invoke the onClose callback to hide the menu
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleCellClick = (cellText) => {
    onCellClick(cellText); // Invoke the callback with the cell text
  };

  if (!position) {
    return null; // Render nothing if position is null
  }

  return (
    <div
      ref={menuRef}
      style={{
        position: "fixed",
        top: `${position.top}px`,
        left: `${position.left}px`,
        width: "170px",
        height: "274px",
        zIndex: "999",
        overflowY: "auto",
        overflowX: "hidden", // Optional, if needed
      }}
    >
      <div className="clear-canvas-cell">
        <UnSelectedMenuCell
          text="Remove All Stickers"
          keyboard_cmd=""
          hasDivider={false}
          onClick={() => handleCellClick("Clear Stickers")}
        />
      </div>
      <div className="clear-canvas-cell">
        <UnSelectedMenuCell
          text="Remove Weapon Model"
          keyboard_cmd=""
          hasDivider={false}
          onClick={() => handleCellClick("Hide Weaponmodel")}
        />
      </div>
      <UnSelectedMenuCell
        text="Organize Stickers"
        keyboard_cmd=""
        hasDivider={false}
        onClick={() => handleCellClick("Organize")}
      />
      {/* <UnSelectedMenuCell
        text="Lock All Stickers"
        keyboard_cmd=""
        hasDivider={false}
        onClick={() => handleCellClick("Lock All Stickers")}
      />
      <UnSelectedMenuCell
        text="Unlock All Stickers"
        keyboard_cmd=""
        hasDivider={false}
        onClick={() => handleCellClick("Unlock All Stickers")}
      /> */}
      <UnSelectedMenuCell
        text="Paste Sticker"
        keyboard_cmd="CTRL + V"
        onClick={() => handleCellClick("Paste")}
      />

      {/* <UnSelectedMenuCell
        text="Flip Weaponmodel"
        keyboard_cmd="SHIFT + F"
        hasDivider={false}
        onClick={() => handleCellClick("Flip Weaponmodel")}
      /> */}

      {/* <UnSelectedMenuCell
        text="Zoom In"
        keyboard_cmd="+"
        onClick={() => handleCellClick("Zoom In")}
      />

      <UnSelectedMenuCell
        text="Zoom Out"
        keyboard_cmd="-"
        onClick={() => handleCellClick("Zoom Out")}
      /> */}
    </div>
  );
};

export default UnSelectedRightClickMenu;
