// userSlice.js or userSlice.ts
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
 
  },
  reducers: {
    setUser: (state, action) => {
      Object.assign(state, action.payload);
    },
    setName: (state, action) => {
      state.Name = action.payload;
    },
    setLastName: (state, action) => {
      state.LastName = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phonenumber = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setZip: (state, action) => {
      state.zip = action.payload;
    },
    setBalance: (state, action) => {
      state.balance = action.payload;
    },
    setCurrencyMultiplier: (state, action) => {
      state.currency_multiplier = action.payload;
    },
    setCurrencyExchange: (state, action) => {
      state.currency_exchange = action.payload;
    },
    setDisplayCurrency: (state, action) => {
      state.display_currency = action.payload;
    },
    setSteamApiKey: (state, action) => {
      state.steamapi_key = action.payload;
    },
    setTradeUrl: (state, action) => {
      state.tradeurl = action.payload;
    },
    setSigninPopupStatus: (state, action) => {
      state.popup_data = action.payload;
    },   
  },
});

export const {
  setUser,
  setName,
  setLastName,
  setEmail,
  setPhoneNumber,
  setAddress,
  setCity,
  setCountry,
  setZip,
  setBalance,
  setCurrencyMultiplier,
  setCurrencyExchange,
  setDisplayCurrency,
  setSteamApiKey,
  setTradeUrl,
  setSigninPopupStatus
} = userSlice.actions;

export default userSlice.reducer;
