import React from "react";
import { NavLink } from "react-router-dom";
import "./TopBar.css";
import { useDispatch, useSelector } from "react-redux";

// Corrected component names
const LeftSection = () => {
  const redux_cart = useSelector((state) => state.cart_session);

  const tabStyle = {
    display: "flex",
    alignItems: "center", // Align text vertically in the center
    justifyContent: "center", // Center text horizontally for wider tabs, if necessary
    height: "100%", // Make tab the full height of the TopBar
    textDecoration: "none",
    color: "white",
    fontFamily: "'Kanit', sans-serif", // Apply the Kanit font
    borderBottom: "4px solid transparent", // Use transparent to maintain consistent height
  };

  const activeStyle = {
    borderBottom: "3px solid #2E8FFF", // Active link underline
  };

  const logoStyle = {
    height: "40px",
    marginRight: "39px",
    marginLeft: "30px",
    paddingRight: "13px",
    userSelect: "none"
  };

  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
      <div className="left-section-topbar">
        <NavLink
          to="/Home"
          style={{ height: "100%", display: "flex", alignItems: "center" }}
        >
          {" "}
          {/* Adjust styling as needed */}
          <img
            src="/images/skinhansa/hansalogo.png"
            alt="Logo"
            style={logoStyle}
          />
        </NavLink>

        {redux_cart.cart_enabled ? (
          <NavLink
            className="topbar-link"
            to="/canvas"
            style={({ isActive }) => ({
              ...tabStyle,
              ...(isActive && activeStyle),
            })}
          >
            STICKER CRAFTS / MARKET
          </NavLink>
        ) : (
          <NavLink
            className="topbar-link"
            to="/canvas"
            style={({ isActive }) => ({
              ...tabStyle,
              ...(isActive && activeStyle),
            })}
          >
            STICKER CRAFTS
          </NavLink>
        )}

        {/*  <NavLink
          className="topbar-link"
          to="/inventory"
          style={({ isActive }) => ({
            ...tabStyle,
            ...(isActive && activeStyle),
          })}
        >
          MY INVENTORY
        </NavLink> */}

        <NavLink
          className="topbar-link"
          to="/about"
          style={({ isActive }) => ({
            ...tabStyle,
            ...(isActive && activeStyle),
          })}
        >
          ABOUT US
        </NavLink>
      </div>
{/* 
      <div className="left-section-topbar-mobile">
        {" "}
        <img src="/images/menu.png" alt="Logo" className="hamburger-menu-btn" />
      </div> */}
    </div>
  );
};

export default LeftSection;
