import React, { useState, useEffect, useRef } from "react";
import StickerResultCell from "./StickerResultCell";
import StickerResultsBottomCell from "./StickerResultsBottomCell";
import { useSelector } from "react-redux";

const StickerSearchResults = ({
  handleStickerClick,
  searchQuery,
  collection,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [stopFetch, setStopFetch] = useState(false);
  const [cellCount, setCellCount] = useState(0);
  const [allFetchedStickers, setAllFetchedStickers] = useState([]);
  const wrapperRef = useRef(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const handleCellClick = (imagePath, name, price) => {
    handleStickerClick(imagePath, name, price);
  };

  useEffect(() => {
    setAllFetchedStickers([]);
    setIsLoading(true);
    setStopFetch(false);
    if (collection !== "") {
      getStickers("Filter", 0);
    } else {
      getStickers("Initial", 0);
    }
  }, [searchQuery, collection]);

  useEffect(() => {
    const handleScroll = () => {
      const wrapper = wrapperRef.current;
      if (
        wrapper &&
        wrapper.scrollTop + wrapper.clientHeight >= wrapper.scrollHeight - 10 &&
        !isLoading &&
        !stopFetch
      ) {
        setIsLoading(true);
        getStickers("Filter", cellCount);
        setCellCount((prevCellCount) => prevCellCount + 15);
      }
    };

    const wrapper = wrapperRef.current;
    if (wrapper) {
      wrapper.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isLoading, stopFetch, cellCount]);

  const getStickers = async (mode, currentCellCount) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));

      const response = await fetch(process.env.REACT_APP_API_URL + "auth/fetchstickers", {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          index: currentCellCount,
          mode: mode,
          search: searchQuery,
          filter: collection,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch stickers");
      }

      const data = await response.json();
      if (data.result.length === 0) {
        setStopFetch(true);
      }

      if (currentCellCount === 0) {
        setAllFetchedStickers(data.result);
      } else {
        setAllFetchedStickers((prevStickers) => [
          ...prevStickers,
          ...data.result,
        ]);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      console.log(allFetchedStickers)
    }
  };

  return (
    <div
      className="results-box"
      style={{
        width: "240px",
        position: "relative",
        top: "0",
        left: "0",
        backgroundColor: "#171723",
        marginTop: "3px",
      }}
    >
      <div
        ref={wrapperRef}
        className="results-wrapper"
        style={{ overflowY: "scroll", height: "calc(100vh - 90px)" }}
      >
        {allFetchedStickers.map((item, index) => (
          /**Sticker _ 9z Team _ Antwerp 2022.png */

          <div key={index}>
            <StickerResultCell
              key={index}
              image_path={
                process.env.REACT_APP_STICKER_URL +
                item.name.replace(/\|/g, "_") + // Replacing | with _
                ".png"
              }
              id={item.item_id}
              name={item.name}
              steam_price={item.steam_price}
              price={item.cheapest_price}
              quantity={item.quantity}
              listing_id={item.min_id}
              internal_item_id={item.internal_item_id}
            />
          </div>
        ))}
        {isLoading && <StickerResultsBottomCell />}
      </div>
    </div>
  );
};

export default StickerSearchResults;
