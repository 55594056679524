import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import tabSystemSlice from './slices/tabSystemSlice';
import { thunk } from 'redux-thunk';
import cartSessionSlice from './slices/cartSessionSlice';
import popupDateSlice from './slices/popupDateSlice';
import balancePopupSlice from './slices/balancePopupSlice';
import stickerFetchingSlice from './slices/stickerFetchingSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    tab_system: tabSystemSlice,
    cart_session: cartSessionSlice,
    popupData: popupDateSlice,
    balance_popup: balancePopupSlice,
    sticker_fetching: stickerFetchingSlice
    
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
