import React, { useEffect, useState } from "react";
import Router from "./Router";
import { CartProvider } from "./components/TopBar/cart/CartContext";

// redux
import store from "./redux/store";
import { Provider } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import {
  setCurrencyMultiplier,
  setUser,
  setCurrencyExchange,
} from "./redux/slices/userSlice";
import MobileHomePage from "./pages/HomePage/Mobile/MobileHomePage";

const App = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 800);

  // Function to update state based on screen width
  const updateScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 800);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  const appStyle = {
    backgroundColor: "#111117",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    paddingLeft: isSmallScreen ? "0px" : "20px",
    paddingRight: isSmallScreen ? "0px" : "20px",
  };

  return (
    <div style={appStyle}>
      <Provider store={store}>
        <ReduxApp isSmallScreen={isSmallScreen} />
      </Provider>
    </div>
  );
};

const ReduxApp = ({ isSmallScreen }) => {
  const reduxUser = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const fetchUser = async () => {
    // Backend URL called by frontend to achieve steam auth.
    const authURL = process.env.REACT_APP_API_URL + "auth/user";

    try {
      const response = await fetch(authURL, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      });

      if (response.ok) {
        const data = await response.json();
        data.user[0].currency_multiplier = dispatch(setUser(data.user[0]));

        dispatch(setCurrencyExchange(data.currencyExchange));
        dispatch(
          setCurrencyMultiplier(
            data.currencyExchange[data.user[0].display_currency]
          )
        );
      } else if (response.status === 404) {
        console.error("User not found");
      } else {
        throw new Error("Authentication failed");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []); // Ensure this runs once after the initial render

  // Conditionally render based on screen size
  return (
    <div>
      {isSmallScreen ? (
        <MobileHomePage />
      ) : (
        <CartProvider>
          <Router user={reduxUser} fetchUser={fetchUser} />
        </CartProvider>
      )}
    </div>
  );
};

export default App;
