import * as THREE from "three";

const textureLoader = new THREE.TextureLoader();

// Shared light settings
const sharedLightSettings = {
  lightPositions: [
    new THREE.Vector3(0, 0, 10), // Center
    new THREE.Vector3(-10, 0, 10), // Left
    new THREE.Vector3(10, 0, 10), // Right
    new THREE.Vector3(0, 10, 10), // Top
    new THREE.Vector3(0, -10, 10), // Bottom
  ],
  lightColors: [
    new THREE.Color(1.0, 1.0, 1.0), // Center
    new THREE.Color(1.0, 1.0, 1.0), // Left
    new THREE.Color(1.0, 1.0, 1.0), // Right
    new THREE.Color(1.0, 1.0, 1.0), // Top
    new THREE.Color(1.0, 1.0, 1.0), // Bottom
  ],
};

export const createWeaponMaterial = ({
  skinTexture,
  intersectionUV = new THREE.Vector2(-1.0, -1.0),
  drawingUV = new THREE.Vector2(0.5, 0.5),
  ambientColor = new THREE.Color(0.2, 0.2, 0.2),
  diffuseColor = new THREE.Color(0.8, 0.8, 0.8),
  specularColor = new THREE.Color(1.0, 1.0, 1.0),
  shininess = 20.0,
}) => {
  return new THREE.ShaderMaterial({
    uniforms: {
      skinTexture: { value: skinTexture },
      intersectionUV: { value: intersectionUV },
      drawingUV: { value: drawingUV },
      ambientColor: { value: ambientColor },
      diffuseColor: { value: diffuseColor },
      specularColor: { value: specularColor },
      shininess: { value: shininess },
      lightPositions: { value: sharedLightSettings.lightPositions },
      lightColors: { value: sharedLightSettings.lightColors },
    },
    vertexShader: `
      varying vec2 vUv;
      varying vec3 vNormal;
      varying vec3 vWorldPosition;

      uniform vec3 lightPositions[5];

      void main() {
        vUv = uv;
        vNormal = normalize(normalMatrix * normal);
        vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      varying vec2 vUv;
      varying vec3 vNormal;
      varying vec3 vWorldPosition;

      uniform sampler2D skinTexture;
      uniform vec2 drawingUV;
      uniform vec3 ambientColor;
      uniform vec3 diffuseColor;
      uniform vec3 specularColor;
      uniform float shininess;
      uniform vec3 lightPositions[5];
      uniform vec3 lightColors[5];

      void main() {
        vec3 normal = normalize(vNormal);

        vec3 finalAmbient = ambientColor;
        vec3 finalDiffuse = vec3(0.0);
        vec3 finalSpecular = vec3(0.0);

        for (int i = 0; i < 5; i++) {
          vec3 lightDir = normalize(lightPositions[i] - vWorldPosition);
          vec3 reflectDir = reflect(lightDir, normal);

          // Ambient lighting
          finalAmbient += ambientColor * lightColors[i] * 0.2; 

          // Diffuse lighting
          float diff = max(dot(normal, lightDir), 0.1);
          finalDiffuse += diffuseColor * lightColors[i] * diff * 0.3;

          // Specular lighting
          vec3 viewDir = normalize(-vWorldPosition);
          float spec = pow(max(dot(reflectDir, viewDir), 0.0), shininess);
          finalSpecular += specularColor * lightColors[i] * spec * 0.2;
        }

        // Combine all components
        vec3 finalColor = finalAmbient + finalDiffuse + finalSpecular;

        // Sample texture color
        vec2 wrappedUV = fract(vUv);
        vec3 textureColor = texture2D(skinTexture, wrappedUV).rgb;

        // Apply the texture color
        finalColor *= textureColor;

        gl_FragColor = vec4(finalColor, 1.0);
      }
    `,
    side: THREE.DoubleSide,
    transparent: true,
    alphaTest: 0.5,
    wireframe: false,
  });
};

export const createScopeMaterial = ({
  envMap,
  ambientColor = new THREE.Color(0.05, 0.05, 0.05),
  diffuseColor = new THREE.Color(0.1, 0.1, 0.1),
  specularColor = new THREE.Color(1.0, 1.0, 1.0),
  shininess = 100.0,
}) => {
  return new THREE.ShaderMaterial({
    uniforms: {
      envMap: { value: envMap },
      ambientColor: { value: ambientColor },
      diffuseColor: { value: diffuseColor },
      specularColor: { value: specularColor },
      shininess: { value: shininess },
      lightPositions: { value: sharedLightSettings.lightPositions },
      lightColors: { value: sharedLightSettings.lightColors },
    },
    vertexShader: `
      varying vec3 vWorldPosition;
      varying vec3 vNormal;

      void main() {
        vNormal = normalize(normalMatrix * normal);
        vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform samplerCube envMap;
      uniform vec3 ambientColor;
      uniform vec3 diffuseColor;
      uniform vec3 specularColor;
      uniform float shininess;
      uniform vec3 lightPositions[5];
      uniform vec3 lightColors[5];
      
      varying vec3 vWorldPosition;
      varying vec3 vNormal;

      void main() {
        vec3 normal = normalize(vNormal);
        vec3 viewDir = normalize(-vWorldPosition);

        // Environment reflection
        vec3 reflection = reflect(viewDir, normal);
        vec3 envColor = textureCube(envMap, reflection).rgb;

        vec3 finalAmbient = ambientColor;
        vec3 finalDiffuse = vec3(0.0);
        vec3 finalSpecular = vec3(0.0);

        for (int i = 0; i < 5; i++) {
          vec3 lightDir = normalize(lightPositions[i] - vWorldPosition);
          vec3 reflectDir = reflect(lightDir, normal);

          // Ambient lighting
          finalAmbient += ambientColor * lightColors[i] * 0.3;

          // Diffuse lighting
          float diff = max(dot(normal, lightDir), 0.1);
          finalDiffuse += diffuseColor * lightColors[i] * diff * 0.3;

          // Specular lighting
          float spec = pow(max(dot(reflectDir, viewDir), 0.0), shininess);
          finalSpecular += specularColor * lightColors[i] * spec * 0.3;
        }

        // Final color combining reflection, lighting, and dark diffuse base
        vec3 finalColor = finalAmbient + finalDiffuse + finalSpecular;
        finalColor = mix(finalColor, envColor, 0.1); // Blend reflection with lighting

        gl_FragColor = vec4(finalColor, 1.0);
      }
    `,
    side: THREE.DoubleSide,
    transparent: true,
    alphaTest: 0.9,
    wireframe: false,
  });
};


export const createStickerMaterial = (imagePath) => {
  const stickerMaterial = new THREE.ShaderMaterial({
    uniforms: {
      textureMap: {
        value: textureLoader.load(imagePath),
      },
    },
    vertexShader: `
      varying vec2 vUv;
      
      void main() {
          vUv = uv;
          vUv.x = vUv.x * 0.8 +0.1; // Stretch and shift the texture horizontally
          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      varying vec2 vUv;
      uniform sampler2D textureMap;
      
      void main() {
          vec4 texColor = texture2D(textureMap, vUv);
          gl_FragColor = texColor;
          
          // Example: make transparent where alpha < 0.5
          if (texColor.a < 0.5) discard;
      }
    `,
    transparent: false,
    side: THREE.FrontSide,
    depthTest: true,
    depthWrite: true,
    polygonOffset: true,
    polygonOffsetFactor: -1,
    polygonOffsetUnits: -1,
  });

  return stickerMaterial;
};

export const createDecalMaterial = (decalDiffuse) => {
  return new THREE.ShaderMaterial({
    uniforms: {
      decalDiffuse: { value: decalDiffuse },
      brightness: { value: 1.2 },
      contrast: { value: 0.8 },
      saturation: { value: 1.0 },
      ambientColor: { value: new THREE.Color(0.2, 0.2, 0.2) },
      diffuseColor: { value: new THREE.Color(0.8, 0.8, 0.8) },
      specularColor: { value: new THREE.Color(1.0, 1.0, 1.0) },
      shininess: { value: 20.0 },
      lightPositions: { value: sharedLightSettings.lightPositions },
      lightColors: { value: sharedLightSettings.lightColors },
    },
    vertexShader: `
      varying vec2 vUv;
      varying vec3 vNormal;
      varying vec3 vViewDir;
      varying vec3 vWorldPosition;

      void main() {
        vUv = uv;
        vUv.x = vUv.x * 0.8 +0.1; // Stretch and shift the texture horizontally

        vNormal = normalize(normalMatrix * normal);
        vViewDir = normalize((modelViewMatrix * vec4(position, 1.0)).xyz);
        vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
      }
    `,
    fragmentShader: `
      uniform sampler2D decalDiffuse;
      uniform float brightness;
      uniform float contrast;
      uniform float saturation;
      uniform vec3 ambientColor;
      uniform vec3 diffuseColor;
      uniform vec3 specularColor;
      uniform float shininess;
      uniform vec3 lightPositions[5];
      uniform vec3 lightColors[5];

      varying vec2 vUv;
      varying vec3 vNormal;
      varying vec3 vViewDir;
      varying vec3 vWorldPosition;

      vec3 adjustContrast(vec3 color, float contrast) {
        return (color - 0.5) * contrast + 0.5;
      }

      vec3 adjustSaturation(vec3 color, float saturation) {
        float intensity = dot(color, vec3(0.2126, 0.7152, 0.0722));
        return mix(vec3(intensity), color, saturation);
      }

      vec3 adjustBrightness(vec3 color, float brightness) {
        return color * brightness;
      }

      void main() {
        vec4 texColor = texture2D(decalDiffuse, vUv);
        vec3 color = texColor.rgb;
        color = adjustBrightness(color, brightness);
        color = adjustContrast(color, contrast);
        color = adjustSaturation(color, saturation);

        vec3 normal = normalize(vNormal);
        vec3 finalAmbient = ambientColor;
        vec3 finalDiffuse = vec3(0.0);
        vec3 finalSpecular = vec3(0.0);

        for (int i = 0; i < 5; i++) {
          vec3 lightDir = normalize(lightPositions[i] - vWorldPosition);
          vec3 reflectDir = reflect(lightDir, normal);
          finalAmbient += ambientColor * lightColors[i] * 0.3;
          float diff = max(dot(normal, lightDir), 0.1);
          finalDiffuse += diffuseColor * lightColors[i] * diff * 0.3;
          vec3 viewDir = normalize(-vWorldPosition);
          float spec = pow(max(dot(reflectDir, viewDir), 0.0), shininess);
          finalSpecular += specularColor * lightColors[i] * spec * 0.5;
        }

        vec3 finalColor = finalAmbient + finalDiffuse + finalSpecular;
        finalColor *= color;
        
        gl_FragColor = vec4(finalColor, texColor.a);
      }
    `,
    transparent: true,
    side: THREE.DoubleSide,
    depthTest: true,
    depthWrite: true,
    polygonOffset: true,
    polygonOffsetFactor: -1,
    polygonOffsetUnits: -1,
  });
};
