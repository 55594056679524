// slices/DetailUserSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const balancePopupSlice = createSlice({
  name: "balance_popup_slice",
  initialState: {
    balance_popup_visible: false,
    selected_payment_method: "Card Payment",
    add_balance_process_stage: 1,
    balance_to_add_amount: 15,

    /**
     * In case of checkout topup required
     */
    is_topup: true,
    topup_amount: 0,
  },
  reducers: {
    /**
     * BALANCE
     */
    showBalancePopup: (state) => {
      state.balance_popup_visible = true;
    },
    hideBalancePopup: (state) => {
      state.balance_popup_visible = false;
    },

    /**
     * PAYMENT METHOD
     */

    setCardPaymentMethod: (state) => {
      state.selected_payment_method = "Card Payment";
    },
    setCryptoPaymentMethod: (state) => {
      state.selected_payment_method = "Crypto Payment";
    },
    setQuickSellPaymentMethod: (state) => {
      state.selected_payment_method = "Quick Sell Payment";
    },
    nullifyPaymentMethod: (state) => {
      state.selected_payment_method = null;
    },

    /**
     * PROCCESS STAGE
     */

    setAddBalanceProcessStage: (state, action) => {
      state.add_balance_process_stage = action.payload.stage;
    },

    /**
     * SET BALANCE ADD AMOUNT
     */
    setBalanceAddAmount: (state, action) => {
      state.balance_to_add_amount = action.payload.amount;
    },

    /**
     * TOPUP CHECKOUT REDUCERS
     */

    setIsTopup: (state) => {
      state.is_topup = true;
    },

    setIsNotTopup: (state) => {
      state.is_topup = false;
      state.topup_amount = 0;
    },

    setTopupAmount: (state, action) => {
      state.topup_amount = action.payload.amount;
    },
  },
});

export const {
  showBalancePopup,
  hideBalancePopup,
  setCardPaymentMethod,
  setCryptoPaymentMethod,
  setQuickSellPaymentMethod,
  nullifyPaymentMethod,
  setAddBalanceProcessStage,
  setBalanceAddAmount,
  setIsTopup,
  setIsNotTopup,
  setTopupAmount,
} = balancePopupSlice.actions;

export default balancePopupSlice.reducer;
