import React, { useState, useEffect } from "react";
import "./AddBalancePopupModal.css";
import { useDispatch, useSelector } from "react-redux";
import {
  hideBalancePopup,
  nullifyPaymentMethod,
  setAddBalanceProcessStage,
  setCardPaymentMethod,
  setCryptoPaymentMethod,
  setQuickSellPaymentMethod,
} from "../../../redux/slices/balancePopupSlice";

const AddBalancePopupModal = () => {
  const dispatch = useDispatch();
  const balance_popup = useSelector((state) => state.balance_popup);
  const [selectedMethod, setSelectedMethod] = useState("card_payment");
  const [amount, setAmount] = useState(`EUR ${balance_popup.topup_amount}`);


  useEffect(() => {
    if (balance_popup.balance_popup_visible) {
      setSelectedMethod("card_payment");
      dispatch(setCardPaymentMethod());
    }
  }, [balance_popup.balance_popup_visible, dispatch]);

  const onClose = () => {
    dispatch(hideBalancePopup());
  };

  const backToFirstPage = () => {
    dispatch(setAddBalanceProcessStage({ stage: 1 }));
  };

  const setPaymentMethod = (method) => {
    setSelectedMethod(method);
    switch (method) {
      case "card_payment":
        dispatch(setCardPaymentMethod());
        break;
      case "crypto_payment":
        dispatch(setCryptoPaymentMethod());
        break;
      case "quick_sell_payment":
        dispatch(setQuickSellPaymentMethod());
        break;
      default:
        dispatch(nullifyPaymentMethod());
        break;
    }
  };

  const proceedtoPaymentPage = () => {
    dispatch(setAddBalanceProcessStage({ stage: 2 }));
  };
  const finishAddBalance = () => {
    dispatch(setAddBalanceProcessStage({ stage: 1 }));
    dispatch(hideBalancePopup());
  };

  useEffect(() => {
  }, [balance_popup]);

  const handleAmountChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
    const numericValue = parseInt(value, 10); // Parse the string as a base-10 integer
    if (!isNaN(numericValue) && numericValue >= 0) {
      setAmount(`EUR ${numericValue}`);
    } else if (value === "") {
      setAmount("EUR 0");
    }
  };

  const selectPresetAmount = (value) => {
    setAmount(`EUR ${value}`);
  };

  if (!balance_popup.balance_popup_visible) return null;

  if (balance_popup.add_balance_process_stage == 1) {
    return (
      <div className="balance-modal-overlay">
        <div className="balance-modal-content">
          <button className="balance-modal-close-button" onClick={onClose}>
            &times;
          </button>
          <div className="balance-modal-left">
            {balance_popup.is_topup && balance_popup.topup_amount !== 0 ? (
              <div>
                <p className="balance-modal-title">
                  Add EUR {balance_popup.topup_amount} to complete the purchase
                </p>
                <p className="balance-modal-subtitle">
                  Add the top-up amount to your account{" "}
                </p>
              </div>
            ) : (
              <p className="balance-modal-title">Add Balance To Your Account</p>
            )}
            <p className="balance-modal-subtitle-select-payment">Select a payment method</p>
            <div className="grid-container">
              <div
                className={`grid-item ${
                  selectedMethod === "card_payment" ? "selected" : ""
                }`}
                onClick={() => setPaymentMethod("card_payment")}
              >
                <img
                  src="images/paymentmethods/cardpayments.png"
                  alt="Payment Method 1"
                />
                <div className="grid-item-text">
                  <span className="grid-item-title">Card Payment</span>
                  <span className="grid-item-subtitle">
                    Mastercard, Visa, etc
                  </span>
                </div>
              </div>
              <div
                className={`grid-item ${
                  selectedMethod === "quick_sell_payment" ? "selected" : ""
                }`}
                onClick={() => setPaymentMethod("quick_sell_payment")}
              >
                <img
                  src="images/paymentmethods/skinsback.jpeg"
                  alt="Payment Method 2"
                />
                <div className="grid-item-text">
                  <span className="grid-item-title">Quick Sell Skins</span>
                  <span className="grid-item-subtitle">
                    Quick sell with skinsback
                  </span>
                </div>
              </div>
              <div
                className={`grid-item ${
                  selectedMethod === "crypto_payment" ? "selected" : ""
                }`}
                onClick={() => setPaymentMethod("crypto_payment")}
              >
                <img
                  src="images/paymentmethods/cryptopayments.png"
                  alt="Payment Method 3"
                />
                <div className="grid-item-text">
                  <span className="grid-item-title">Cryptocurrency</span>
                  <span className="grid-item-subtitle">
                    Wide range of crypto
                  </span>
                </div>
              </div>
              {/* Add more grid items as needed */}
            </div>
          </div>
          <div className="balance-modal-right">
            {/* Content for the right section */}
            <span className="grid-item-title-right">Add Balance</span>

            {balance_popup.selected_payment_method ? (
              <span className="grid-item-subtitle-right">
                Add balance with {balance_popup.selected_payment_method}
              </span>
            ) : (
              <span className="grid-item-subtitle-right">
                Add balance to your account
              </span>
            )}

            {balance_popup.selected_payment_method ? (
              <div className="right-side-modal-payment-selection-wrapper">
                <span className="right-side-modal-payment-selection-left-text">
                  Payment Method
                </span>
                <span className="grid-item-subtitle-right-right-text">
                  {balance_popup.selected_payment_method}
                </span>
              </div>
            ) : (
              <div className="right-side-modal-payment-selection-wrapper">
                <span className="right-side-modal-payment-selection-left-text">
                  Payment Method
                </span>
                <span className="grid-item-subtitle-right-right-text">
                  Not Selected
                </span>
              </div>
            )}

            <div className="divider-right-side-modal"></div>

            {balance_popup.selected_payment_method ? (
              <button
                className="modal-proceed-to-payment-button"
                onClick={proceedtoPaymentPage}
              >
                PROCEED WITH{" "}
                {balance_popup.selected_payment_method.toUpperCase()}
              </button>
            ) : (
              <button
                className="modal-proceed-to-payment-button-disabled"
                onClick={proceedtoPaymentPage}
                disabled
              >
                PROCEED TO PAYMENT
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }

  if (
    balance_popup.add_balance_process_stage == 2 &&
    balance_popup.selected_payment_method !== "Quick Sell Payment"
  ) {
    return (
      <div className="balance-modal-overlay">
        <div className="balance-modal-content">
          <button
            className="balance-modal-back-button"
            onClick={backToFirstPage}
          >
            {"BACK"}
          </button>
          <button className="balance-modal-close-button" onClick={onClose}>
            &times;
          </button>

          <div className="balance-modal-left">
            {balance_popup.is_topup && balance_popup.topup_amount !== 0 ? (
              <div>
                <p className="balance-modal-title">
                  Add EUR {balance_popup.topup_amount} to complete the purchase
                </p>
                <p className="balance-modal-subtitle">
                  Add the top-up amount to your account{" "}
                </p>
              </div>
            ) : (
              <p className="balance-modal-title">Add Balance To Your Account</p>
            )}
            <p className="balance-modal-subtitle-enter-amount">Enter Amount</p>
            <input
              type="text"
              className="amount-input"
              value={amount}
              onChange={handleAmountChange}
            />
            <div className="preset-amounts-grid">
              {["1", "5", "10", "25", "50", "100"].map((value) => (
                <div
                  key={value}
                  className="preset-amount"
                  onClick={() => selectPresetAmount(value)}
                >
                  EUR {value}
                </div>
              ))}
            </div>
          </div>
          <div className="balance-modal-right">
            {/* Content for the right section */}

            <span className="grid-item-title-right">Add Balance</span>

            {balance_popup.selected_payment_method ? (
              <span className="grid-item-subtitle-right">
                Add balance with {balance_popup.selected_payment_method}
              </span>
            ) : (
              <span className="grid-item-subtitle-right">
                Add balance to your account
              </span>
            )}

            {balance_popup.selected_payment_method ? (
              <div>
                <div className="right-side-modal-payment-selection-wrapper">
                  <span className="right-side-modal-payment-selection-left-text">
                    Payment Method
                  </span>
                  <span className="grid-item-subtitle-right-right-text">
                    {balance_popup.selected_payment_method}
                  </span>
                </div>
                <div className="right-side-modal-payment-selection-wrapper">
                  <span className="right-side-modal-payment-selection-left-text">
                    Amount
                  </span>
                  <span className="grid-item-subtitle-right-right-text">
                    {amount}
                  </span>
                </div>
              </div>
            ) : (
              <div className="right-side-modal-payment-selection-wrapper">
                <span className="right-side-modal-payment-selection-left-text">
                  Payment Method
                </span>
                <span className="grid-item-subtitle-right-right-text">
                  Not Selected
                </span>
              </div>
            )}

            <div className="divider-right-side-modal"></div>

            {balance_popup.selected_payment_method ? (
              <button
                className="modal-proceed-to-payment-button-stage-two"
                onClick={finishAddBalance}
              >
                ADD {amount} WITH{" "}
                {balance_popup.selected_payment_method.toUpperCase()}
              </button>
            ) : (
              <button
                className="modal-proceed-to-payment-button-disabled"
                onClick={finishAddBalance}
                disabled
              >
                PROCEED TO PAYMENT
              </button>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    /**
     * QUICK SELL
     */
    return (
      <div className="balance-modal-overlay">
        <div className="balance-modal-content">
          <button
            className="balance-modal-back-button"
            onClick={backToFirstPage}
          >
            {"BACK"}
          </button>
          <button className="balance-modal-close-button" onClick={onClose}>
            &times;
          </button>
        </div>
      </div>
    );
  }
};

export default AddBalancePopupModal;
