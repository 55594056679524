import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const ProfilePicture = ({ user }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const profilePictureRef = useRef(null);
  const redux_cart = useSelector((state) => state.cart_session);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        profilePictureRef.current !== event.target
      ) {
        closeDropdown();
      } else if (profilePictureRef.current === event.target) {
        toggleDropdown();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // Dynamically calculate padding based on cart state
  const getDropdownPadding = () => {
    return redux_cart.cart_enabled ? "-4px" : "-46px";
  };

  return (
    <div
      ref={profilePictureRef}
      style={{
        position: "relative",
        display: "inline-block",
        height: "30px",
        width: "30px",
        marginRight: "20px",
      }}
    >
      <div
        onClick={toggleDropdown}
        style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      >
        <img
          src={user.avatar}
          alt="User Avatar"
          style={{
            height: "30px",
            width: "30px",
          }}
        />
        <div
          style={{
            marginLeft: "10px",
            fontFamily: "'Kanit', sans-serif", // Apply the Kanit font
          }}
        >
          {user.name}
        </div>
        <span style={{ marginLeft: "0px", fontFamily: "'Kanit', sans-serif" }}>
          {showDropdown ? "▶" : "▼"}
        </span>
      </div>

      {showDropdown && (
        <div
          ref={dropdownRef}
          style={{
            position: "absolute",
            top: "150%",
            left: getDropdownPadding(), // Correct function call here
            transform: "translateX(0%)",
            backgroundColor: "#171723",
            boxShadow: "0px 8px 16px 0px rgba(0,0,0,0.2)",
            width: "115px",
            zIndex: 3,
          }}
        >
          <NavLink
            className="dropdown-filter-search"
            to="/profile"
            style={{
              display: "block",
              padding: "10px",
              textDecoration: "none",
              color: "white",
              fontFamily: "'Kanit', sans-serif",
            }}
            onClick={closeDropdown}
          >
            Profile
          </NavLink>
    {/*       <NavLink
            className="dropdown-filter-search"
            to="/settings"
            style={{
              display: "block",
              padding: "10px",
              textDecoration: "none",
              color: "white",
              fontFamily: "'Kanit', sans-serif",
            }}
            onClick={closeDropdown}
          >
            Settings
          </NavLink> */}
          <NavLink
            className="dropdown-filter-search"
            to="/logout"
            style={{
              display: "block",
              padding: "10px",
              textDecoration: "none",
              color: "white",
              fontFamily: "'Kanit', sans-serif",
            }}
            onClick={closeDropdown}
          >
            Sign Out
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default ProfilePicture;
