// MobileHomePage.jsx

import React from 'react';
import './MobileHomePage.css';

const MobileHomePage = () => {
    return (
        <div className="mobile-home-page">
            {/* Logo */}
            <div className="logo-container">
                <img src={"images/skinhansa/hansalogo.png"} alt="Logo" className="logo-image" />
            </div>

            {/* Showcase Image */}
            <div className="showcase-container">
                <img src={"images/home/banner_homepage.png"} alt="Showcase" className="showcase-image" />
            </div>

            {/* Title */}
            <h1 className="title">
                Explore our new sticker crafting 3D engine for CS2
            </h1>

            {/* Subtitle */}
            <p className="subtitle">
                Our crafting engine enables testing of stickers on different skins before purchase!
            </p>

            {/* Desktop-only Availability Subtitle */}
            <p className="subtitle subtitle-desktop-only">
                CURRENTLY ONLY AVAILABLE IN DESKTOP BROWSERS!
            </p>
        </div>
    );
};

export default MobileHomePage;
