import React, { useState, useEffect } from "react";
import "./CartItemBox.css";
import CheckoutCartCell from "./CheckoutCartCell";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  makeStockPopupVisible,
  makeStockPopupHidden,
  showCartDropDown,
  hideCartDropDown,
} from "../../../redux/slices/cartSessionSlice";

const CartItemBox = ({ user }) => {

  const redux_cart = useSelector((state) => state.cart_session);

  const cartItems = redux_cart.cart_items;

  const totalQuantity = redux_cart.cart_items.length;

  return (
    <div className="cart-item-box">
      <div className="top-div">
        <p
          style={{
            fontSize: "16px",
            fontWeight: "800",
            color: "white",
          }}
        >
          <div>
            {totalQuantity} {totalQuantity === 1 ? "ITEM" : "ITEMS"} IN CART
          </div>
        </p>
      </div>
      <div className="middle-div">
        {cartItems
          .slice()
          .reverse()
          .map((item) => (
            <div key={item.id} style={{ borderTop: "1px solid #1F1F2F" }}>
              <CheckoutCartCell
                img={item.item_image_src}
                name={item.item_name}
                // desc={"DESC"}
                price={item.item_price}
                user={user}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default CartItemBox;
