import React, { useEffect, useState } from "react";
import SelectedMenuCell from "./SelectedMenuCell";
import { useSelector } from "react-redux";

const SelectedRightClickMenu = ({ x, y, onCellClick }) => {
  const [position, setPosition] = useState({ left: x + 470, top: y - 100 });

  useEffect(() => {
    const menuWidth = 170; // Width of the menu
    const menuHeight = 408; // Height of the menu
    const topPadding = 123; // New top padding
    const bottomPadding = 20; // New bottom padding
    const rightPadding = 275; // New right padding

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    // Calculate the left position of the menu
    let newLeft = Math.min(x + 470, windowWidth - menuWidth - rightPadding);

    // Calculate the top position of the menu
    let newTop = Math.min(y - 100, windowHeight - menuHeight - bottomPadding);
    if (newTop < topPadding) {
      newTop = topPadding; // Ensure menu stays within top padding
    }

    // Check if menu is too close to bottom
    if (newTop + menuHeight > windowHeight - bottomPadding) {
      newTop = windowHeight - menuHeight - bottomPadding;
    }

    setPosition({ left: newLeft, top: newTop });
  }, [x, y]);

  const handleCellClick = (cellText) => {
    onCellClick(cellText); // Invoke the callback with the cell text
  };

  const redux_cart = useSelector((state) => state.cart_session);

  return (
    <div
      style={{
        position: "fixed",
        top: `${position.top}px`,
        left: `${position.left}px`,
        width: "170px",
        zIndex: "999",
        overflowY: "auto",
        overflowX: "hidden", // Optional, if needed
      }}
    >
      {redux_cart.checkout_stage <= 2 && redux_cart.cart_enabled ? (
        <div className="add-to-cart-cell">
          <SelectedMenuCell
            text="Add to cart"
            keyboard_cmd="B"
            hasDivider={false}
            onClick={() => handleCellClick("Add to cart")}
          />
        </div>
      ) : (
        <div />
      )}

      <div className="remove-from-canvas-cell">
        <SelectedMenuCell
          text="Remove Sticker"
          keyboard_cmd="DEL"
          hasDivider={false}
          onClick={() => handleCellClick("Remove from canvas")}
        />
      </div>
   {/*    <SelectedMenuCell
        text="Lock Movement"
        keyboard_cmd=""
        hasDivider={false}
        onClick={() => handleCellClick("Lock Sticker")}
      /> */}
      <SelectedMenuCell
        text="Copy"
        keyboard_cmd="CTRL + C"
        hasDivider={false}
        onClick={() => handleCellClick("Copy")}
      />
      <SelectedMenuCell
        text="Duplicate"
        keyboard_cmd=""
        hasDivider={true}
        onClick={() => handleCellClick("Duplicate")}
      />

      <SelectedMenuCell
        text="Bring Forward"
        keyboard_cmd="UP"
        onClick={() => handleCellClick("Bring Forward")}
      />
      <SelectedMenuCell
        text="Bring to Front"
        keyboard_cmd=""
        onClick={() => handleCellClick("Bring to Front")}
      />
      <SelectedMenuCell
        text="Bring Back"
        keyboard_cmd="DOWN"
        onClick={() => handleCellClick("Bring Back")}
      />
      <SelectedMenuCell
        text="Bring to Back"
        keyboard_cmd=""
        hasDivider={true}
        onClick={() => handleCellClick("Bring to Back")}
      />

      <SelectedMenuCell
        text="Rotate 45° Right"
        keyboard_cmd="RIGHT"
        onClick={() => handleCellClick("Rotate 45° Right")}
      />
      <SelectedMenuCell
        text="Rotate 90° Right"
        keyboard_cmd=""
        onClick={() => handleCellClick("Rotate 90° Right")}
      />
      <SelectedMenuCell
        text="Rotate 45° Left"
        keyboard_cmd="LEFT"
        onClick={() => handleCellClick("Rotate 45° Left")}
      />
      <SelectedMenuCell
        text="Rotate 90° Left"
        keyboard_cmd=""
        onClick={() => handleCellClick("Rotate 90° Left")}
      />
    </div>
  );
};

export default SelectedRightClickMenu;
