import React, { useState, useEffect } from "react";
import "./Checkbox.css";
import CustomCheckbox from "./CustomCheckbox";

const CheckboxDropdown = ({
  name,
  options,
  keys,
  onFilterChange,
  defaultOpen = false,
  deafaultCheckboxvalue = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [selectedKeys, setSelectedKeys] = useState(
    deafaultCheckboxvalue ? keys : []
  );

  useEffect(() => {
    onFilterChange(selectedKeys);
  }, []);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleChange = (optionIndex) => {
    const key = keys[optionIndex];
    const currentIndex = selectedKeys.indexOf(key);
    const newSelectedKeys = [...selectedKeys];

    if (currentIndex === -1) {
      newSelectedKeys.push(key);
    } else {
      newSelectedKeys.splice(currentIndex, 1);
    }

    setSelectedKeys(newSelectedKeys);
    onFilterChange(newSelectedKeys);
  };

  const handleOnlyOne = (optionIndex) => {};

  return (
    <>
      <hr className="checkbox-divider" />
      <div className="checkbox-dropdown">
        <summary onClick={toggleOpen}>
          <span>{name}</span>
          <span style={{ marginRight: "10px" }}>{isOpen ? "-" : "+"}</span>
        </summary>

        {isOpen && (
          <div className="dropdown-section">
            {options.map((option, index) => (
              <div key={index} className="checkbox-option">
                <div className="checkbox-label-container">
                  <CustomCheckbox
                    checked={selectedKeys.includes(keys[index])}
                    onChange={() => handleChange(index)}
                    style={{ marginLeft: "10px" }}
                  />
                  <span className="option-label" style={{ userSelect: "none" }}>
                    {option}
                  </span>
                </div>
                {/* TODO denna gör inget? */}
                {/* <button className="only-button">ONLY</button> */}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default CheckboxDropdown;
