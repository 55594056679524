import React, { useEffect, useState } from "react";
import "./CustomCheckbox.css";

const CustomCheckbox = ({ checked: defaultChecked, onChange }) => {
  const [checked, setChecked] = useState(defaultChecked);

  const toggleChecked = () => {
    const newChecked = !checked;
    setChecked(newChecked);
    onChange(newChecked);
  };

  return (
    <button
      className={`custom-checkbox ${checked ? "checked" : ""}`}
      onClick={toggleChecked}
    ></button>
  );
};

export default CustomCheckbox;
