import React, { useEffect, useState } from "react";
import "./TabSystem.css"; // Import the CSS file

import TabCell from "./TabCell";

// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveTab,
  addTab,
  removeTab,
  updateTabName,
  moveTab,
} from "../../../redux/slices/tabSystemSlice";

const TabSystemContainer = () => {
  const dispatch = useDispatch();
  const tabs = useSelector((state) => state.tab_system.tabs);
  const activeTab = useSelector((state) => state.tab_system.active_tab);

  // Local state to manage the delay for tab selection
  const [isDelayActive, setIsDelayActive] = useState(false);

  const addTabHandler = () => {
    dispatch(addTab());
  };

  const removeTabHandler = (indexToRemove) => {
    if (indexToRemove != tabs.length - 1) {
      dispatch(removeTab(indexToRemove));
    }
  };

  const updateTabNameHandler = (index, newName) => {
    dispatch(updateTabName({ index, name: newName }));
  };

  const moveTabHandler = (fromIndex, toIndex) => {
    dispatch(moveTab({ fromIndex, toIndex }));
  };

  const handleSelect = (index) => {
    // If delay is active, do nothing
    if (isDelayActive) return;

    dispatch(setActiveTab(index));

    // Set delay
    setIsDelayActive(true);
    setTimeout(() => {
      setIsDelayActive(false);
    }, 1000); // 1 second delay
  };

  return (
    <div className="tab-system-wrapper">
      {tabs.map((tab, index) => (
        <TabCell
          key={index}
          tab={tab}
          isSelected={activeTab === index}
          removeTab={tabs.length > 1 && index != tabs.length-1 ? () => removeTabHandler(tab.index) : null}
          onTabTextChange={updateTabNameHandler}
          onTabMove={moveTabHandler}
          onSelect={handleSelect}
        />
      ))}
      {tabs.length < 5 && (
        <div className="add-new-tab-button-wrapper" onClick={addTabHandler}>
          <img src="images/plus.png" alt="Add Tab" />
        </div>
      )}
    </div>
  );
};

export default TabSystemContainer;
