import React from "react";
import "./HomePage.css";
import { NavLink } from "react-router-dom";

const HomePage = () => {

  return (
    <div className="home-page-wrapper">
      <div className="home-page-banner">
        <div className="home-page-banner-text-box">
          <div className="home-page-banner-text-box-title">
            Explore our new sticker crafting 3D engine for CS2
          </div>
          <div className="home-page-banner-text-box-subtitle">
            Our crafting engine enables testing of stickers on different skins
            before purchase!
          </div>
          <div className="home-page-banner-text-box-buttons-container">
            <NavLink to="/canvas">
              {" "}
              {/* Adjust styling as needed */}
              <button className="home-page-banner-text-box-left-button">
                OPEN CRAFTING ENGINE
              </button>{" "}
            </NavLink>
            <button className="home-page-banner-text-box-right-button">
              ABOUT US
            </button>
          </div>
        </div>
        <div className="home-page-banner-back-box">
          <img src="images/home/banner_homepage.png" alt="Background" />{" "}
        </div>
      </div>
      <div className="supporters-banner-homepage">
        {/* <img
          src="images/home/trustpilot-light.png"
          alt="Image 1"
          className="banner-image"
        />
        <img
          src="images/home/trustly.png"
          alt="Image 2"
          className="banner-image"
        /> */}
      </div>

      {/* <CommunityCraftsHomePage /> */}
    </div>
  );
};

export default HomePage;
