import React, { useState, useEffect } from "react";
import "./CheckoutPage.css"; // Import CSS file for styling
import CartItemBox from "../../components/Checkout/CartItemBox/CartItemBox";
import CartPayment from "./CartPages/CartPayment";
import CartComplete from "./CartPages/CartComplete";
import CartInformationComponent from "./CartPages/CartInformation"; // Renamed component
import CartSteamTrade from "./CartPages/CartSteamTrade/CartSteamTrade";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  setCheckoutStage,
  endCheckout,
  hideCartDropDown,
  setOrderId,
} from "../../redux/slices/cartSessionSlice";

const PaginationBox = ({ currentStep }) => {
  const dispatch = useDispatch();
  const steps = [
    { number: 1, label: "PAYMENT" },
    { number: 2, label: "STEAM TRADE" },
    { number: 3, label: "COMPLETE" },
  ];

  return (
    <div className="pagination-box">
      {steps.map((step, index) => (
        <React.Fragment key={step.number}>
          <div className="step-container">
            <button
              className={`button ${
                currentStep === step.number
                  ? "active"
                  : currentStep > step.number
                  ? "completed"
                  : "inactive"
              }`}
            >
              {step.number}
            </button>
            <div
              className={`step-label ${
                currentStep === step.number ? "active" : ""
              }`}
            >
              {step.label}
            </div>
          </div>
          {index !== steps.length - 1 && (
            <div
              className={`divider ${
                currentStep > step.number ? "white" : "gray"
              }`}
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const BottomBox = ({
  currentStep,
  handleProceedToPayment,
  handleProceedToSteamTrade,
  user,
}) => {
  const getStepComponent = (step) => {
    switch (step) {
      case 1:
        return (
          <CartPayment
            onProceedToSteamTrade={handleProceedToSteamTrade}
            user={user}
          />
        );
      case 2:
        return <CartSteamTrade />;
      case 3:
        return <CartComplete />;
      default:
        return null;
    }
  };

  return <div className="bottom-box">{getStepComponent(currentStep)}</div>;
};

const CheckoutPage = ({ user }) => {
  const redux_cart = useSelector((state) => state.cart_session);
  const dispatch = useDispatch();

  var currentStep = redux_cart.checkout_stage;

  const handleProceedToPayment = (newOrderId) => {
    // setCurrentStep(2);
    // You can perform further actions here
    dispatch(setOrderId({orderId : newOrderId}))
    dispatch(setCheckoutStage({ checkoutStage: 1 }));
  };

  const handleProceedToSteamTrade = (newOrderId) => {
    dispatch(setOrderId({orderId : newOrderId}))
    dispatch(setCheckoutStage({ checkoutStage: 2 }));
    // You can perform further actions here
  };

  useEffect(() => {
    dispatch(hideCartDropDown());
  }, []);

  return (
    <div className="checkout-page">
      <PaginationBox currentStep={currentStep} />
      <BottomBox
        currentStep={currentStep}
        handleProceedToPayment={handleProceedToPayment}
        handleProceedToSteamTrade={handleProceedToSteamTrade}
        user={user}
      />
    </div>
  );
};

export default CheckoutPage;
