import React from "react";
const Logout = () => {
  window.location.href = process.env.REACT_APP_API_URL + 'logout'
  return (
    <div className=''>
      <p></p>
    </div>
  )
}
export default Logout

