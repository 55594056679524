import React, { useState, useEffect } from "react";
import BalanceSection from "./BalanceSection";
import ProfilePicture from "./ProfilePicture";
import CartSection from "./cart/CartSection";
import LoginButton from "./LoginButton";
import "./TopBar.css";
import { useDispatch, useSelector } from "react-redux";

const RightSection = ({ user }) => {
  const [showBalance, setShowBalance] = useState(window.innerWidth > 1200);
  const redux_cart = useSelector((state) => state.cart_session);

  useEffect(() => {
    const handleResize = () => {
      setShowBalance(window.innerWidth > 1200);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isLoggedIn = user && Object.keys(user).length > 0;

  return (
    <div className="right-section-topbar">
      {isLoggedIn ? (
        <>
          {showBalance && redux_cart.cart_enabled && <BalanceSection user={user} />}
          <ProfilePicture user={user} />
          {redux_cart.cart_enabled ? (
            <CartSection user={user} />
          ) : (
            <div style={{ marginRight: "0px" }} />
          )}
        </>
      ) : (
        <LoginButton />
      )}
    </div>
  );
};

export default RightSection;
