import React, { useEffect, useRef, useState } from "react";
import "../../../App.css";
import "./ThisCraftBar.css";
import SelectWeaponField from "./SelectWeaponField";
import { useCart } from "../../TopBar/cart/CartContext";
import { useDispatch, useSelector } from "react-redux";
import {
  requestStickerSelection,
  setSelectedStickerIndex,
  removeSticker,
  requestStickerReload,
  removeAllStickersInTab,
  verifyCanvasClearing,
  requestCanvasClearing,
  changeStickerRenderOrder,
  requestRemoveSelectedSticker,
} from "../../../redux/slices/tabSystemSlice";
import {
  addItem,
  makeStockPopupVisible,
  removeItem,
} from "../../../redux/slices/cartSessionSlice";

import { setPopupData } from "../../../redux/slices/popupDateSlice";

const calculateDisplayCurrencyPrice = (price, user) => {

  // Check if user is logged in
  if (Object.keys(user).length > 0) {
    var steam_price = (price * user.currency_multiplier).toFixed(2);

    if (price !== 0) {
      return (steam_price * user.currency_multiplier).toFixed(2).toString();
    } else {
      return "?";
    }
  } else {
    return "EUR " + price.toString();
  }
 
};


const UnselectedStickerBox = ({
  name,
  price,
  steam_price,
  img,
  index,
  listing_id,
}) => {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.tab_system.active_tab);
  const redux_cart = useSelector((state) => state.cart_session);
  const user = useSelector((state) => state.user);

  const handleStickerClick = () => {
    dispatch(
      setSelectedStickerIndex({
        tabId: activeTab,
        selectedIndex: index,
      })
    );
    dispatch(requestStickerSelection());
  };

  return (
    <div
      className="unselected-sticker-box"
      onClick={handleStickerClick}
      style={{ cursor: "pointer" }}
    >
      <div className="unselected-sticker-box-left">
        <img
          src={img}
          style={{
            width: "80%",
            height: "80%",
            marginLeft: "10px",
            marginTop: "10px",
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt="Unselected sticker"
        />
      </div>
      <div className="unselected-sticker-box-right">
        <p className="unselected-sticker-name">{name}</p>

        {listing_id != null && redux_cart.cart_enabled ? (
          <p className="unselected-sticker-price">
            {/* {user.display_currency} {calculateDisplayCurrencyPrice(price, user)} */}
          </p>
        ) : (
          <p className="unselected-sticker-price">
            Steam Price: {user.display_currency}{" "}
            {calculateDisplayCurrencyPrice(steam_price, user)}
          </p>
        )}
      </div>
    </div>
  );
};

const SelectedStickerBox = ({
  sticker,
  addStickerToCart,
  removeStickerFromCanvas,
}) => {
  function radiansToDegrees(radians) {
    const degrees = radians * (180 / Math.PI);
    return degrees;
  }

  const dispatch = useDispatch();
  const redux_cart = useSelector((state) => state.cart_session);
  const user = useSelector((state) => state.user);

  const addStickerToReduxCart = () => {
    dispatch(
      setPopupData({
        name: sticker.name,
        internal_id: sticker.internal_item_id,
        image_path: sticker.image_path,
      })
    );
    dispatch(makeStockPopupVisible());
  };

  return (
    <div className="selected-sticker-box">
      <div className="top-box-selected-sticker">
        <div className="image-box-selected-sticker">
          <img
            src={sticker.image_path}
            style={{
              width: "80%",
              height: "80%",
              marginLeft: "6px",
              marginTop: "12px",
              objectFit: "cover",
              objectPosition: "center",
            }}
            alt="Selected sticker"
          />
        </div>
        <div className="info-box-selected-sticker">
          <p className="selected-sticker-name">{sticker.name}</p>

          {redux_cart.cart_enabled ? (
            sticker.listing_id != null ? (
              <div>
                <p className="selected-sticker-price">
                  SEK{" "}
                  {/* {calculateDisplayCurrencyPrice(sticker.price, user)} */}
                </p>

                {sticker.price < sticker.steam_price ? (
                  <div className="discountbox-thiscraft">
                    <p>
                      {"-" +
                        (
                          (1 - sticker.price / sticker.steam_price) *
                          100
                        ).toFixed(0) +
                        "%"}{" "}
                    </p>
                  </div>
                ) : (
                  <div />
                )}

                {redux_cart.checkout_stage <= 2 ? (
                  <button
                    className="selected-sticker-add-to-cart"
                    onClick={addStickerToReduxCart}
                  >
                    ADD TO CART
                  </button>
                ) : (
                  <div style={{ marginBottom: "16px" }}></div>
                )}
              </div>
            ) : (
              <div className="selected-sticker-out-of-stock-banner">
                OUT OF STOCK
              </div>
            )
          ) : (
            <div>
              <p className="selected-sticker-price">
                Steam Price: {user.display_currency}{" "}
                {calculateDisplayCurrencyPrice(sticker.steam_price, user)}
                
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="bottom-box-selected-sticker">
        <div className="coordinate-box-selected-sticker">
          <div className="coordinate-left-side">
            <div className="coordinate-left-side-top">
              <p
                className="x-coordinate-left-label"
                style={{ color: "lightgray", fontWeight: 800 }}
              >
                X
              </p>
              <p className="x-coordinate-right-value">
                {Math.round(sticker.x_pos)}
              </p>
            </div>
            <div className="coordinate-left-side-bottom">
              <p
                className="rotation-coordinate-left-label"
                style={{ color: "lightgray", fontWeight: 800 }}
              >
                R
              </p>
              <p className="rotation-coordinate-right-value">
                {Math.ceil(
                  radiansToDegrees(sticker.clockwise_rotatation) * 10
                ) / 10}
                °
              </p>
            </div>
          </div>
          <div className="coordinate-right-side">
            <div className="coordinate-right-side-top">
              <p
                className="y-coordinate-left-label"
                style={{ color: "lightgray", fontWeight: 800 }}
              >
                Y
              </p>
              <p className="y-coordinate-right-value">
                {Math.round(sticker.y_pos)}
              </p>
            </div>
            <div className="coordinate-right-side-bottom">
              <p
                className="z-coordinate-left-label"
                style={{ color: "lightgray", fontWeight: 800 }}
              >
                Z
              </p>
              <p className="z-coordinate-right-value">
                {Math.round(sticker.z_render_order)}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="remove-canvas-wrapper">
        <button
          className="selected-sticker-remove-from-canvas"
          onClick={removeStickerFromCanvas}
        >
          REMOVE FROM CANVAS
        </button>
      </div>
    </div>
  );
};

const ThisCraftBar = ({ }) => {
  const weapon_skins = require("./skin_data.json");

  const dispatch = useDispatch();
  const tabs = useSelector((state) => state.tab_system.tabs);
  const activeTab = useSelector((state) => state.tab_system.active_tab);
  const appliedStickers = tabs[activeTab].stickers_applied;
  const selectedStickerIndex = tabs[activeTab].selected_sticker_index;
  const selected_sticker = appliedStickers[selectedStickerIndex];

  const [canvasStickers, setCanvasStickers] = useState([]);
  const { addToCart } = useCart();

  useEffect(() => {
    setCanvasStickers(appliedStickers);
  }, [appliedStickers]);

  const addStickerToCart = () => {
    if (selected_sticker) {
      const { id, name, price, image_path } = selected_sticker;
      addToCart({ id, image_src: image_path, name, price });
    }
  };

  const removeStickerFromCanvas = () => {
    // Implement your remove sticker logic here

    dispatch(
      removeSticker({
        tabId: activeTab,
        stickerIndex: selected_sticker.index,
      })
    );

    dispatch(requestRemoveSelectedSticker());

    dispatch(requestStickerReload());
  };

  const clearCanvas = () => {
    console.log("CLEAR CANVAS FROM SIDEBAR");
    dispatch(removeAllStickersInTab({ tabId: activeTab }));
    dispatch(requestCanvasClearing());
  };

  const sortedStickers = [...appliedStickers]
    .filter((sticker) => !sticker.has_been_removed)
    .sort((a, b) => b.z_render_order - a.z_render_order);

  

  return (
    <div className="this-craft-sidebar-container">
      <div className="skin-selector-box">
        <p>SEARCH FOR ANY CS2 SKIN</p>
        <SelectWeaponField items={weapon_skins} />
      </div>

      {sortedStickers.length > 0 ? (
        <div className="stickers-on-canvas-box">
          <p>{sortedStickers.length}/5 STICKERS APPLIED</p>
        </div>
      ) : (
        <div />
      )}

      {sortedStickers.map((sticker) =>
        sticker === selected_sticker ? (
          <SelectedStickerBox
            key={sticker.index}
            sticker={selected_sticker}
            addStickerToCart={addStickerToCart}
            removeStickerFromCanvas={removeStickerFromCanvas}
          />
        ) : (
          <UnselectedStickerBox
            key={sticker.index}
            name={sticker.name}
            price={sticker.price}
            steam_price={sticker.steam_price}
            img={sticker.image_path}
            index={sticker.index}
            listing_id={sticker.listing_id}
          />
        )
      )}
      {sortedStickers.length === 0 ? (
        <div className="empty-craft-cell" style={{ userSelect: "none" }}>
          <div style={{ textAlign: "center" }}>
            <img
              src="images/canvas_empty.png"
              alt="Empty Cart Image"
              className="sticker-image-empty-craft"
              style={{
                width: "70%",
                height: "auto",
                margin: "0 auto",
                marginTop: "10px",
                pointerEvents: "none", // Prevent dragging
              }}
            />
            <p
              style={{
                color: "#fff",
                fontWeight: "bold",
                fontSize: "14px",
                marginTop: "10px",
                marginBottom: "0px",
              }}
            >
              THIS CRAFT IS EMPTY
            </p>

            <div
              style={{
                fontSize: "10px",
                width: "150px",
                marginLeft: "40px",
                marginTop: "5px",
                marginBottom: "30px",
              }}
            >
              <p>YOU CAN APPLY STICKERS ON ANY CS2 SKIN!</p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <button
            className="selected-stickers-clear-canvas"
            onClick={clearCanvas}
          >
            CLEAR CANVAS
          </button>
          <div className="divider-box"></div>

          {/*  <div className="open-in-game-wrapper">
            <button className="open-in-game-box">
              <p>OPEN IN-GAME</p>
            </button>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default ThisCraftBar;
