import React from "react";
import "../../../App.css";
import "./StickerSearch.css";

const StickerResultsBottomCell = ({ image_path, name, price, onClick }) => {
  const handleClick = () => {
    onClick(image_path, name, price); // Call the onClick function with image_path parameter
  };

  return (
    <div
      className="sticker-results-bottom-cell"
      style={{
        height: "200px",
        width: "100%",
        position: "relative",
        marginBottom: "20px",
        display: "flex",
        alignItems: "center",
        marginTop: "20px"
      }}
      onClick={handleClick} // Handle click event
    >
      <div className="bottom-loader"></div>
    </div>
  );
};

export default StickerResultsBottomCell;
